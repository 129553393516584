import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
  import dayjs from 'dayjs';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    updateData:any;
    saferValue:any;
    existingData: any;
}

interface S {
    options: any,
    subOptions:any,
    drinkCount: number,
    drinkCountSecond: number,
    drinkCountSecondOften: number,
    cannabisCount:number,
    drinkCountOften: number,
    CannabisSecond:number,
    selectedDate: string,
    valueOther:string,
    formattedDateInter:string,
    openCalender: string,
    openTime:boolean,
    textdollars:string,
    textdollarssecond:string,
    OthersResponse:string,
    optionsDate:any,
    selectedValue: any,
    selectedValueSubOption:any,
    selectedValueOption: any,
    selectedValueCalendar:any,
    CannabisButtonCalled:boolean,
    allowCannabisPostApiCall:boolean,
}

interface SS {
    id: any;
}
interface NumberIndexedObject {
    [key: string]: string;
}

export default class CannabisController extends BlockComponent<
    Props,
    S,
    SS
> {
    getCannabis:string=""
    postCannabis:string=""
    putSubstanceCannabisApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            options: [],
            subOptions:[],
            optionsDate:[],
            selectedValue: 'Reduce how much',
            selectedValueSubOption:'Ounces',
            selectedValueOption: '',
            selectedValueCalendar:'',
            drinkCount: 0,
            drinkCountSecond: 0,
            drinkCountSecondOften: 0,
            CannabisSecond:0,
            valueOther:'',
            selectedDate: "",
            formattedDateInter:'',
            openCalender: "",
            openTime:false,
            textdollars:'',
            textdollarssecond:'',
            OthersResponse:'',
            cannabisCount:0,
            drinkCountOften: 0,
            CannabisButtonCalled:false,
            allowCannabisPostApiCall:false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        this.handleChangeOther = this.handleChangeOther.bind(this);
        this.handleChangeOption=this.handleChangeOption.bind(this);
        this.handleDollars=this.handleDollars.bind(this);
        this.handleDollarsSecond=this.handleDollarsSecond.bind(this);
        this.handleInputCannabisChange=this.handleInputCannabisChange.bind(this);
        this.handleInputCannabisSecond=this.handleInputCannabisSecond.bind(this);
    }
   
    async componentDidMount() {
        this.generateOptions();
        this.generateOptionsCalendar();
        this.generateSubOptions();
        this.setState({
          selectedValue: this.props.existingData.selectedValue,
          selectedValueOption: this.props.existingData.selectedValueOption,
          drinkCount: this.props.existingData.drinkCount,
          drinkCountSecond: this.props.existingData.drinkCountSecond,
          drinkCountSecondOften: this.props.existingData.drinkCountSecondOften,
          drinkCountOften: this.props.existingData.drinkCountOften,
          selectedValueSubOption: this.props.existingData.selectedValueSubOption,
          selectedDate: this.props.existingData.selectedDate,
          OthersResponse: this.props.existingData.OthersResponse,
          formattedDateInter: this.props.existingData.formattedDateInter,
          textdollars: this.props.existingData.textdollars,
          textdollarssecond: this.props.existingData.textdollarssecond,
          CannabisSecond: this.props.existingData.CannabisSecond,
          cannabisCount: this.props.existingData.cannabisCount,
        }, () => localStorage.setItem('selectedSubValue',this.state.selectedValue));
      }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
    
        const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (isResponseMessage) {
          if (this.getCannabis === responseData && !responseJson?.errors) {
           this.getCannabisApiResponse(responseJson)
          }
          else if(this.postCannabis === responseData && !responseJson?.errors){
            const selectedCardId = responseJson.substance_goal_response.id;
            setStorageData("selectedCardId", selectedCardId);
            setStorageData("newData", true);
            this.props.updateData(responseJson?.message);
           /* istanbul ignore next */
            localStorage.setItem("idSend",responseJson?.substance_goal_response?.id)
          } else if(this.putSubstanceCannabisApiCallId === responseData && !responseJson?.errors){
            this.props.updateData(responseJson?.message);
          }
        };  
      }
    handleChangeOther(event:any){
      this.setState({OthersResponse:String(event?.target?.value || '').replace(/^\s+/, '')})
    }
    getCannabisApiResponse=(responseJson:any)=>{
        const CannabisResponses = responseJson?.responses;
        this.setState({
          drinkCountOften: CannabisResponses?.reduce_how_often?.answer1 || 0,
          drinkCountSecondOften: CannabisResponses?.reduce_how_often?.answer2 || 0,
          OthersResponse: CannabisResponses?.others?.answer || '',
          formattedDateInter: CannabisResponses?.stop_completely?.answer2,
          selectedValueOption: CannabisResponses?.stop_completely?.answer1 || '',
          drinkCount: CannabisResponses?.reduce_how_much?.Ounces?.answer1 || 0,
          drinkCountSecond: CannabisResponses?.reduce_how_much?.Ounces?.answer2 || 0,
          textdollars: CannabisResponses?.reduce_how_much?.Dollars?.answer1 || 0,
          textdollarssecond: CannabisResponses?.reduce_how_much?.Dollars?.answer2 || 0,
          CannabisSecond: CannabisResponses?.reduce_how_much?.Hits?.answer2 || 0,
          cannabisCount: CannabisResponses?.reduce_how_much?.Hits?.answer1 || 0
        });        
        if (CannabisResponses?.reduce_how_much) {
          this.setState({ selectedValue: 'Reduce how much' });
          if(CannabisResponses?.reduce_how_much?.Dollars){
            this.setState({selectedValueSubOption:'$$'})
          }
          else if(CannabisResponses?.reduce_how_much?.Hits){
            this.setState({selectedValueSubOption:'Hits'})
          }
          //here
          else if(CannabisResponses?.reduce_how_much?.Ounces){
            this.setState({selectedValueSubOption:'Ounces'})
          }
        } else if (CannabisResponses?.reduce_how_often) {
          this.setState({ selectedValue: 'Reduce how often' });
        } else if (CannabisResponses?.stop_completely) {
          this.setState({ selectedValue: 'Stop completely' });
        } else if (CannabisResponses?.others) {
          this.setState({ selectedValue: 'Other' });
        }
       else if(CannabisResponses?.safer_healthier){
        this.setState({ selectedValue: 'Safer/healthier' });
       }

      }
    getCannabisData = () => {
        let data = localStorage.getItem("token") || "{}";
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCannabis = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getCannabis
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      };


    CannabisPostAPiCall = async () => {
      localStorage.setItem("selectedHeading",'Cannabis')
      const selectedCardId = await getStorageData("selectedCardId");
      if(this.state.allowCannabisPostApiCall){
       let data = localStorage.getItem("token") || "{}"
        const dataSend = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        let responses = {};
        if (this.state.selectedValue === "Reduce how much") { 
          let selectedSubOptionData={};
          if (this.state.selectedValueSubOption === "Ounces") {
            localStorage.setItem("value4",String(this.state.drinkCount))
            localStorage.setItem("value5",String(this.state.drinkCountSecond))
            localStorage.setItem("value6",'ounces')
            selectedSubOptionData = {
              Ounces:{
              question1: "Over the last week, how many Ounces did you have on a typical day when you use cannabis?",
              answer1: this.state.drinkCount,
              question2: "What would you like to reduce that amount to this week?",
              answer2: this.state.drinkCountSecond
              }
            };
          } else if (this.state.selectedValueSubOption === "$$") {
            localStorage.setItem("value4",String(this.state.textdollars))
            localStorage.setItem("value5",String(this.state.textdollarssecond))
            localStorage.setItem("value6",'dollars')
            selectedSubOptionData = {
              Dollars:{
              question1: "Over the last week, how many $$ did you have on a typical day when you use cannabis?",
              answer1: this.state.textdollars,
              question2: "What would you like to reduce that amount to this week?",
              answer2: this.state.textdollarssecond
              }
            };
          } else if (this.state.selectedValueSubOption === "Hits") {
            localStorage.setItem("value4",String(this.state.cannabisCount))
            localStorage.setItem("value5",String(this.state.CannabisSecond))
            localStorage.setItem("value6",'hits')
            selectedSubOptionData = {
              Hits:{
              question1: "Over the last week, how many Hits did you have on a typical day when you use cannabis?",
              answer1: this.state.cannabisCount,
              question2: "What would you like to reduce that amount to this week?",
              answer2: this.state.CannabisSecond
              }
            };
          }
         
          responses = {
            reduce_how_much: selectedSubOptionData
          };
        }
        else if (this.state.selectedValue === "Reduce how often") {
          localStorage.setItem("value4",String(this.state.drinkCountOften))
          localStorage.setItem("value5",String(this.state.drinkCountSecondOften))
          localStorage.setItem("value6",'')
          responses = {
            reduce_how_often: {
              question1: "Out of the past 7 days, how many days did you use cannabis?",
              answer1: this.state.drinkCountOften,
              question2: "Out of the NEXT 7 days, how many days do you want to use cannabis?",
              answer2: this.state.drinkCountSecondOften,
            }
          };
        } else if (this.state.selectedValue === "Stop completely") {
          localStorage.setItem("value4",String(this.state.formattedDateInter))
          localStorage.setItem("value5",'')
          localStorage.setItem("value6",'')
          responses = {
            stop_completely: {
              question1: "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first.Are you sure you want to take a break from cannabis at this point?",
              answer1: this.state.selectedValueOption,
              question2:'Way to commit to taking a break from cannabis! What day this week do you want to start?',
              answer2:this.state.formattedDateInter
            }
          };
        } else if (this.state.selectedValue === "Other") {
          localStorage.setItem("value4",this.state.OthersResponse)
          localStorage.setItem("value5",'')
          localStorage.setItem("value6",'')
          responses = {
            others: {
              answer:this.state.OthersResponse
            },
          };
        }
        else if(this.state.selectedValue==='Safer/healthier'){
          localStorage.setItem("value5",'')
          localStorage.setItem("value6",'')
          localStorage.setItem("value4",'')
          responses={
            safer_healthier:{
        
            }
          }
        }
        
        const httpBody = { 
          substance_goal_response: {
            category_name: "Cannabis",
            responses: responses
          }
        };
          
        if(selectedCardId) {
          const postAlcoholDataSave = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.putSubstanceCannabisApiCallId = postAlcoholDataSave.messageId;
          postAlcoholDataSave.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_custom_forms/substance_goal_responses/${selectedCardId}`
          );

          postAlcoholDataSave.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            dataSend
          );
          postAlcoholDataSave.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
          );
          postAlcoholDataSave.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          runEngine.sendMessage(postAlcoholDataSave.id, postAlcoholDataSave);
        } else {
          const postCannabisData = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.postCannabis = postCannabisData.messageId;
          postCannabisData.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.cannabisPoint}`
          );
          postCannabisData.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            dataSend
          );
          postCannabisData.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.postApiMethodType
          );
          postCannabisData.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          runEngine.sendMessage(postCannabisData.id, postCannabisData);    
          removeStorageData("selectedCardId");
          removeStorageData("newData");
          removeStorageData("firstSelectedCardId");
          removeStorageData("firstNewData");
        }
      }
    }
      
    checkAllCannabisRequired = () => {
      const {selectedValue,OthersResponse,selectedValueSubOption,textdollars,textdollarssecond} = this.state
      let allowCannabisPostApiCall = false;
      const isTextValid = (textValue: string | any[]) => textValue && textValue.length >= 1;
      const isValidLastWeekText = /^\d*$/.test(this.state.textdollars);
      const isValidThisWeekText = /^\d*$/.test(this.state.textdollarssecond);
      const isValidCanabieOtherText = /^[^a-zA-Z]*$/.test(this.state.OthersResponse || '');
    switch (selectedValue) {   

      case 'Reduce how much':
        if (selectedValueSubOption === '$$') {
          if (isTextValid(textdollars) && isTextValid(textdollarssecond) && isValidLastWeekText && isValidThisWeekText) {
            allowCannabisPostApiCall = true;
          }
        }  else if (selectedValueSubOption === 'Ounces') {
          if (this.state.drinkCount > 0) {  
            allowCannabisPostApiCall = true;
          }
        } else if (selectedValueSubOption === 'Hits') {
          if (this.state.cannabisCount > 0) {
            allowCannabisPostApiCall = true;
          }
        }
        this.setState({ allowCannabisPostApiCall }, () => {
          this.CannabisPostAPiCall();
        });
        break;
      case 'Stop completely':
        if (this.state.selectedValueOption?.length > 1 && this.state.formattedDateInter?.length > 3) {
          allowCannabisPostApiCall = true;
        } 
        this.setState({ allowCannabisPostApiCall }, () => {
          this.CannabisPostAPiCall();
        });
        break;
      case 'Other':
        if (isTextValid(OthersResponse) && !isValidCanabieOtherText) {
          allowCannabisPostApiCall = true;
        }
        this.setState({ allowCannabisPostApiCall }, () => {
          this.CannabisPostAPiCall();
        });
        break;
        case 'Reduce how often':
          if (this.state.drinkCountOften > 0) {
            allowCannabisPostApiCall = true;
          }
          this.setState({ allowCannabisPostApiCall }, () => {
            this.CannabisPostAPiCall();
          });
          break;
      default :
      allowCannabisPostApiCall = true;
      this.setState({ allowCannabisPostApiCall }, () => {
        this.CannabisPostAPiCall();
      });
    }  
    }



    SaveCannabisData= () => {
     
      this.setState({
        CannabisButtonCalled:true
      })
      this.checkAllCannabisRequired()
      
    }
    borderHandle(option:any){
      return  this.state.selectedValue === option ? '1px solid #00A7D1' : '1px solid #E3E3E3'
    }
    handlecolor(option:any){
      return this.state.selectedValue === option ? '#EFF8FB' : 'transparent'
    }
    handleBGcolor(option:any){
      return this.state.selectedValueOption === option ? '#EFF8FB' : 'transparent';
    }
    handleOptionValue(option:any){
      return this.state.selectedValueOption === option ? '1px solid #00A7D1' : '1px solid #E3E3E3';
    }
    openPopUp(key: number) {
        this.setState({
          openCalender: key.toString()
        });
      }
      generateOptionsCalendar() {
        const today = new Date();
        const optionsDate = [
            { 
                value: 'today', 
                label: `Today, ${this.formatDate(today)}`, 
                date: this.formatDateYYYYMMDD(today) 
            },
        ];
        for (let i = 1; i <= 4; i++) {
            const nextDate = new Date(today);
            nextDate.setDate(today.getDate() + i);
            optionsDate.push({
                value: `nextDay${i}`,
                label: `${this.formatDate(nextDate)}`,
                date: this.formatDateYYYYMMDD(nextDate) // Add the new field here
            });
        }
        optionsDate.push({ value: 'other', label: 'Other Day', date: '' }); // Add date field for 'Other Day'
        this.setState({ optionsDate });
      }
      formatDateYYYYMMDD(date:any) {
        if (!date){
          return
        }
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      }
    formatDate(date:any) {
      if (!date) {
         return;
      }
        const dateMoment = moment(date); 
        const formattedDate = dateMoment.format('ddd, MMMM D'); 
        const isToday = dateMoment.isSame(moment(), 'day'); 
        return isToday ? formattedDate.replace(',', '') : formattedDate;
    }
    handleCannabisSecondInc= () => {
        this.setState((prevState) => ({
            CannabisSecond: prevState.CannabisSecond + 1
        }));
    };
    handleIncrementCannabis = () => {
        this.setState((prevState) => ({
            cannabisCount: prevState.cannabisCount + 1
        }));
    };
    
     
    handleIncrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount + 1
        }));
    };
    handleIncrementSecond = () => {
        this.setState((earlierState) => ({
            drinkCountSecond: earlierState.drinkCountSecond + 1
        }));
    };

    handleIncrementOften = () => {
      this.setState((prevState) => {
        if (prevState.drinkCountOften < 7) {
          return { drinkCountOften: prevState.drinkCountOften + 1 };
        }
        return null;
      });
    };
    handleIncrementSecondOften = () => {
      this.setState((earlierState) => {
        if (earlierState.drinkCountSecondOften < 7) {
          return { drinkCountSecondOften: earlierState.drinkCountSecondOften + 1 };
        }
        return null; 
      });
    };
    handleInputChangeOften = (event: any) => {
      const value = Number(event.target.value);
      if (!isNaN(value) && value >= 0 && value <= 7) {
        this.setState({ drinkCountOften: value });
      }
    };
    handleInputChangeSecondOften = (event: any) => {
      const value = Number(event.target.value);
      if (!isNaN(value) && value >= 0 && value <= 7) {
        this.setState({ drinkCountSecondOften: value });
      }
    };
    handleInputCannabisChange = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ cannabisCount: Number(value) });
        }
    };
    handleInputChange = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCount: Number(value) });
        }
    };
    handleInputChangeSecond = (event: any) => {
        const answer = event.target.value;
        if (!isNaN(answer) && answer >= 0) {
            this.setState({ drinkCountSecond: Number(answer) });
        }
    };
    handleDecrementOften = () => {
        this.setState((prevState) => ({
            drinkCountOften: prevState.drinkCountOften > 0 ? prevState.drinkCountOften - 1 : 0
        }));
    };

    handleCannabisSecondDec=() => {
        this.setState((prevState) => ({
            CannabisSecond: prevState.CannabisSecond > 0 ? prevState.CannabisSecond - 1 : 0
        }));
    };

    handleDecrementSecondOften = () => {
        this.setState((prevState) => ({
            drinkCountSecondOften: prevState.drinkCountSecondOften > 0 ? prevState.drinkCountSecondOften - 1 : 0
        }));
    };
    handleCannabisDecrement=()=>{
        this.setState((prevState) => ({
            cannabisCount: prevState.cannabisCount > 0 ? prevState.cannabisCount - 1 : 0
        }));
    };
    
    handleDecrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount > 0 ? prevState.drinkCount - 1 : 0
        }));
    };
    handleDecrementSecond = () => {
        this.setState((prevState) => ({
            drinkCountSecond: prevState.drinkCountSecond > 0 ? prevState.drinkCountSecond - 1 : 0
        }));
    };


    generateOptions() {

        const options = [
            { value: 'Reduce how much', label: `Reduce how much` },
        ];
        options.push({ value: 'Reduce how often', label: 'Reduce how often' });

        options.push({ value: 'Stop completely', label: 'Stop completely' });
        options.push({ value: 'Other', label: 'Other' });
        this.setState({ options });
    }
    generateSubOptions() {
        const subOptions = [
            { value: 'Ounces', label: `Ounces` },
        ];
        subOptions.push({ value: '$$', label: '$$', });
        subOptions.push({ value: 'Hits', label: 'Hits' });
        this.setState({ subOptions });
    }
    handleSafer=()=>{
      if(typeof this.props.saferValue === 'function'){
    this.props.saferValue('Yes');}}

    handleChangeDates= (event:any) => {
        this.setState({ formattedDateInter: event.date })
  };
    handleChange = (event: any) => {
      this.setState({CannabisButtonCalled:false})
      localStorage.setItem('selectedSubValue',event.target.value)
      this.setState({
        selectedValue: event.target.value,
        drinkCountOften: 0,
        drinkCountSecondOften: 0,
        selectedValueSubOption:'Ounces',
        OthersResponse: '',
        selectedValueOption: 0,
        drinkCount: 0,
        drinkCountSecond: 0,
        formattedDateInter:'no'
      })

    }
    handleInputCannabisSecond=(event: any) => {
        this.setState({ CannabisSecond: event.target.value });
    }
    handleDollarsSecond(event:any){ 
        this.setState({textdollarssecond:String(event?.target?.value || '').replace(/^\s+/, '')})
    }
    openTimer() {
        this.setState({
          openTime: true
        });
      }
      
      closePopUp() {
        this.setState({
          openCalender: ""
        });
      }
    handleDollars(event:any){
        this.setState({ textdollars: String(event?.target?.value || '').replace(/^\s+/, '')})
    }
    handleChangeSubOption=(event:any)=>{
        this.setState({ selectedValueSubOption: event.target.value });
        this.setState({CannabisButtonCalled:false})
            this.setState({drinkCount:0})
            this.setState({drinkCountSecond:0})
            this.setState({textdollarssecond:''})
            this.setState({textdollars:''})
            this.setState({cannabisCount:0})
            this.setState({CannabisSecond:0})
    }
    handleChangeOption = (event: any) => {   
        this.setState({ selectedValueOption: event.target.value });
    };
    confirmCal() {
        this.setState({formattedDateInter:this.state.selectedDate, openCalender: "",})
      }
    handleDataCalendar=(newDate:any)=>{
        const formattedDate = dayjs(newDate.$d).format('YYYY/MM/DD');
        this.setState({selectedDate:formattedDate})
      }
    handlePopupData(key: any) {
        if (key) {
          return true;
        } else {
          return false;
        }
      }
    handleChangeCalendar= (event: any) => {
        this.setState({valueOther:''})
        this.setState({ selectedValueCalendar: event.target.value });
    };

}


