import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  isLoading: boolean;
  valueCheck:string;
  gridItems: any, 
  scrollOffset:number,
  visibleItemCount:any,
  checkedItems: any,
  getCategoryComplete:any
  AddCategorieButtonCalled:any,
  userName:any,
  MoveTOSaferPage:any,
  buttonText: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CategoriessubController extends BlockComponent<
  Props,
  S,
  SS
> {
  getCategoryHandleValue=""
  categoryHandleKey=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    this.state = {
      isLoading: false,
      valueCheck:'',
      scrollOffset: 0,
      visibleItemCount: 0,
      gridItems: [], 
      checkedItems: {},
      getCategoryComplete:[],
      AddCategorieButtonCalled:false,
      userName:'',
      MoveTOSaferPage:'',
      buttonText: '',
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
   throttle(fn:any, wait:any) {
    let isCalled = false;
    return function(...args: any) {
      if (!isCalled) {
        fn(...args);
        isCalled = true;
        setTimeout(() => {
          isCalled = false;
        }, wait);
      }
    };
  }
  throttledResize = this.throttle(() => {
    this.handleResize();
  }, 100); 
  async componentDidMount() {
    const valuesSequence = await getStorageData("selectedSubstance");
    const BackToSaferuseStrategies = await getStorageData("BackToSaferuseStrategies");
    this.setState({
      userName: localStorage.getItem("username"),
      valueCheck:BackToSaferuseStrategies,
      buttonText: BackToSaferuseStrategies ? "Add Safer-use Strategies" : "Set Goals",
      getCategoryComplete: valuesSequence ? valuesSequence.split(",") : [],
    })
      setTimeout(() => {
        this.handleResize();
      }, 0);
      this.fetchGridItems();
      removeStorageData("selectedCardId");
      removeStorageData("selectedCategory");
      removeStorageData("weekValue");
      removeStorageData("goalValue");
      removeStorageData("susSelectedCategory");
      removeStorageData("susWeekValue");
      removeStorageData("newData");
      window.addEventListener('resize', this.throttledResize);
  }

  async componentWillUnmount() {
    window.removeEventListener('resize', this.throttledResize);
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
      if (this.getCategoryHandleValue === responseData && !responseJson.errors) {
        let answer: any[]=[];
        responseJson?.categories?.map((item:any)=>{
           answer.push(item.category_name);
        })
        this.setState({
          getCategoryComplete: answer
        });
      }
       /* istanbul ignore next */
      if(this.categoryHandleKey === responseData && !responseJson.errors && responseJson?.message == 'Categories updated successfully'){
        let movetosafer = await getStorageData("BackToSaferuseStrategies")
    this.setState(
      {
        MoveTOSaferPage: movetosafer
      },
      () => {
        if (this.state.MoveTOSaferPage && this.state.MoveTOSaferPage !== "") {
          setTimeout(() => {
            this.setState({ isLoading: false });
            this.props.navigation.navigate(this.state.MoveTOSaferPage);
          }, 1000);
        } else {
          setTimeout(() => {           
            this.setState({ isLoading: false });
            this.props.navigation.navigate("Goals");
          }, 1000);
        }
      }
    );
      }
    };    
  }
  CatergoriesHandle= () => {
    let data = localStorage.getItem("token") || "{}"
    const { getCategoryComplete } = this.state;
    let answer=Array.from(new Set(getCategoryComplete));
    const dataSend = {
      "Content-Type": configJSON.categoryApiContentType,
      token: data
    };
    const httpBody = {
      user_category: {
        category_names: answer
      }
    };

    const getCategory = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.categoryHandleKey = getCategory.messageId;

    getCategory.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoryEndPoint}`
    );

    getCategory.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      dataSend
    );
    getCategory.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    getCategory.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getCategory.id, getCategory);
  }
  
  async introductionPage(){
    const BackToSaferuseStrategies = await getStorageData("BackToSaferuseStrategies");
    const redirectPath = !!BackToSaferuseStrategies ? "SaferuseStrategies" : "Goals";
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), redirectPath);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  CategoriesGet = () => {
    let data = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.categoryApiContentType,
      token: data
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryHandleValue = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryShowPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  loginPage=()=>{
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
  setGoals=()=>{
    this.setState({
      AddCategorieButtonCalled:true,
    })
    if(this.state.getCategoryComplete?.length>0){
    localStorage.setItem("selectedTab",'')
    localStorage.setItem("selectedHeading",'')
      /* istanbul ignore next */
    localStorage.setItem("idSend","")
    this.setState({ isLoading: true });
    this.CatergoriesHandle();
    }
  }
  hamburgerPage=()=>{
    localStorage.setItem("pageNavigate","Categoriessubcategories")
    this.props.navigation.navigate("HamburgerMenu")
  }
  fetchGridItems = () => {
    const itemsFromApi = [
        { id: 1, label: 'Alcohol' },
        { id: 2, label: 'Cannabis' },
        { id: 3, label: 'Tobacco or Nicotine' },
        { id: 4, label: 'Uppers',description:'(Ritalin, Adderall, meth, speed, coke)' },
        { id: 5, label: 'Downers',description:'(Oxy, fentanyl, valium, kratom)' },
        // { id: 6, label: 'Others' },
    ]; 
    
    this.setState({ 
        gridItems: itemsFromApi 
    });
}
handleScrollLeft = () => {
  this.setState((prevState) => ({
    scrollOffset: Math.max(prevState.scrollOffset - prevState.visibleItemCount, 0),
  }));
};
handleValue(index:any){
  return index===5?'40px':'10px'
}
handleResize = () => {
  const containerWidth = window.innerWidth;
  const visibleItemCount = Math.floor(containerWidth / 237); 

  this.setState({
    visibleItemCount: visibleItemCount > 0 ? visibleItemCount : 1, 
  });
};

handleScrollRight = () => {
  this.setState((prevState, props) => ({
    scrollOffset: Math.min(prevState.scrollOffset + prevState.visibleItemCount, this.state.gridItems.length - prevState.visibleItemCount),
  }));
};
handleCheckboxChange = (id:any) => { 
  const { getCategoryComplete } = this.state;
  if (getCategoryComplete?.includes(id)) {
      this.setState({
          getCategoryComplete: getCategoryComplete?.filter((item: any) =>
              item !== id
        )
      });
  } else {
      this.setState({
          getCategoryComplete: [...getCategoryComplete, id]
      })}
}
}
