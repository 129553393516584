import React from "react";
// Customizable Area Start
import {
    Grid,
    Box,
    Typography,
    Button,
    Tab,
    Tabs,
    Backdrop,
    CircularProgress
} from "@mui/material";
import {
    arrow,
    bottomBannerImg,
    arrowright,
    backwardbutton,
    rightmovearrow,
    plusButton,
    four,
    alcoholTabImg,
    cannabisImg,
    tobaccoTabImg,
    uppersTabImg,
    downersTabImg,
    menuIconImg,
    addButtonImg,
    viewImg,
    award_a6,
    one
} from './assets';
export const configJSON = require("./config.js");
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Confetti from "react-confetti";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// Customizable Area End
import SaferUserStrategiesController, {
    Props,
} from './SaferUserStrategiesController.web';

export default class SaferUserStrategies extends SaferUserStrategiesController {
    private carouselRef: React.RefObject<HTMLDivElement>;
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.carouselRef = React.createRef<HTMLDivElement>();
        // Customizable Area End
    }
    // Customizable Area Start

    saferUserCard = (items: any) => {
        const selectedCardId = localStorage.getItem("selectedCardId");
        const { title, id, img, why, how, showArrow } = items;
        const isAlreadyCreated = !selectedCardId && this.state.selectedCategories.map(category => category.title)?.includes(title);
        const isSelectedCard = this.state.selectedCardTitle?.includes(id) || this.state.selectedCard === title || isAlreadyCreated;
        return (
            <Box
                sx={{
                    border: isSelectedCard ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                    borderRadius: '16px',
                    padding: { xs: '12px', sm: '8px' },
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    backgroundColor: isSelectedCard ? '#EFF8FB' : '#FFFFFF',
                    marginBottom: { xs: '16px', sm: '0' },
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1,
                    width: {
                        xs: 'auto',
                        sm: '200px',
                        md: '250px',
                        lg: '310px',
                    },
                    height: {
                        xs: '200px',
                        sm: '200px',
                        md: '250px',
                        lg: '250px',
                    },
                    cursor: isAlreadyCreated ? "initial" : "",
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', top: 0, background: isSelectedCard ? '#EFF8FB' : '#FFFFFF', zIndex: '1000', padding: { xs: '8px', sm: '12px' } }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={`${img?.default || ''}`} alt="icon" style={{ width: 16, height: 16, marginRight: 8 }} />
                        <Typography variant="h6" component="div"
                            sx={{
                                fontFamily: "Chromatica-Regular", fontSize: { xs: "16px", sm: "18px", md: "20px" }, fontWeight: "700"
                            }}
                        >
                            {title}
                        </Typography>
                    </Box>
                    {showArrow && (
                        <div style={{ position: 'relative', cursor: 'pointer' }}>
                            <img
                                src={`${viewImg?.default || ''}`}
                                alt="view-icon"
                                style={{
                                    borderRadius: '50%',
                                    border: '1px solid #E0E0E0'
                                }}
                            />
                            <div onClick={() => this.selectCardHandler(items, this.state.activeTab, isAlreadyCreated)} data-test-id='selected' style={{ justifyContent: 'space-between' }}>
                                <img
                                    src={isSelectedCard ? `${plusButton?.default || ''}` : `${addButtonImg?.default || ''}`}
                                    alt="add-icon"
                                    style={{
                                        width: '24px',
                                        height: '24px',
                                        position: 'absolute',
                                        top: '9px',
                                        marginLeft: '9px',
                                        zIndex: 1,
                                        cursor: isAlreadyCreated ? "initial" : "pointer",
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </Box>
                <Box sx={{
                    marginTop: '16px',
                    height: { xs: 'auto', sm: 'auto' },
                    overflowY: 'auto',
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#888',
                        borderRadius: '10px',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        backgroundColor: '#555',
                    },
                }}>
                    <Typography
                        sx={{ color: "#B7B7B7", fontFamily: "Chromatica-Regular", fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "500" }}
                        variant="subtitle1" color="textSecondary" gutterBottom>
                        WHY?
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Chromatica-Regular", fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "400", color: "#252525"
                        }}
                        variant="body1">{why}</Typography>
                    <Typography variant="subtitle1" color="textSecondary" gutterBottom sx={{
                        marginTop: '16px', color: "#B7B7B7", fontFamily: "Chromatica-Regular", fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "500"
                    }}>
                        HOW?
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Chromatica-Regular", fontSize: { xs: "12px", sm: "14px", md: "16px" }, fontWeight: "400"
                        }}
                        variant="body1">{how}</Typography>
                </Box>
            </Box>
        );
    }

    firstRowRender = () => {
        const { gridItems, currentIndex, itemsPerPage } = this.state;
        const visibleItems = gridItems.firstRow && gridItems.firstRow.slice(currentIndex.firstRow, currentIndex.firstRow + itemsPerPage.firstRow);
        const showNext = currentIndex.firstRow + itemsPerPage.firstRow < gridItems?.firstRow?.length;
        const showPrevious = currentIndex.firstRow > 0;
        return (
            <Grid container sx={{ overflow: 'hidden', marginTop: { xs: '10px', sm: '15px' }, marginBottom: { xs: '10px', sm: '15px' } }}>
                <div style={{ display: 'flex', gap: '10px', flex: "auto" }} ref={this.carouselRef}>
                    {showPrevious && (
                        <div style={{ height: '48px', width: '48px', position: 'relative', alignSelf: 'center' }}>
                        <Box sx={{
                            height: { xs: '32px', sm: '40px', md: '48px' },
                            width: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center'
                        }}>
                            <img
                                onClick={() => this.handlePrevious(this.state.gridItems.firstRow, 'firstRow')}
                                style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                                src={backwardbutton.default}
                                alt="Previous"
                                data-test-id="scrollleft"
                            />
                        </Box>
                    </div>
                    )}
                        {visibleItems?.map((item: any, index: number) => (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4} sx={{ display: 'flex' }}
                                className={`carousel-item ${index === currentIndex.firstRow ? 'active' : ''}`} style={{ position: 'relative' }}>
                                {this.saferUserCard(item)}
                            </Grid>
                        ))}
                    <div style={{ height: '48px', width: '48px', position: 'relative', alignSelf: 'center' }}>
                    <Box sx={{
                        height: { xs: '32px', sm: '40px', md: '48px' },
                        width: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center'
                    }}>
                        {showNext && (
                            <img
                                onClick={() => this.handleNext(gridItems.firstRow, 'firstRow')}
                                style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                                src={rightmovearrow.default}
                                alt="Next"
                                data-test-id="scrollleftNextOne"
                            />
                        )}
                    </Box>
                    </div>
                </div>
            </Grid>
        )
    }

    secondRowRender = () => {
        const { gridItems, currentIndex, itemsPerPage } = this.state;
        const visibleItems = gridItems.secondRow && gridItems.secondRow.slice(currentIndex.secondRow, currentIndex.secondRow + itemsPerPage.secondRow);
        const showNext = currentIndex.secondRow + itemsPerPage.secondRow < gridItems?.secondRow?.length;
        const showPrevious = currentIndex.secondRow > 0;
        return (
            <Grid container sx={{ overflow: 'hidden', marginTop: { xs: '10px', sm: '15px' }, marginBottom: { xs: '10px', sm: '15px' } }}>
                    <div style={{ display: 'flex', flex: "auto", gap: '10px' }}>
                    {showPrevious && (
                        <div style={{ height: '48px', width: '48px', position: 'relative', alignSelf: 'center' }}>
                        <Box sx={{
                            width: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center',
                            height: { xs: '32px', sm: '40px', md: '48px' },
                        }}>
                            <img
                                onClick={() => this.handlePrevious(this.state.gridItems.secondRow, 'secondRow')}
                                style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                                src={backwardbutton.default}
                                alt="Previous"
                                data-test-id="scrollleftone"
                            />
                        </Box>
                        </div>
                    )}
                    {visibleItems?.map((item: any, keyIndex: number) => {
                        return (
                            <Grid item key={keyIndex} xs={12} sm={6} md={4} lg={4} xl={4}
                                style={{ position: 'relative', display: 'flex' }}>
                                {this.saferUserCard(item)}
                            </Grid>
                        )
                    })}
                    <div style={{ height: '48px', width: '48px', position: 'relative', alignSelf: 'center' }}>
                        {showNext && gridItems.secondRow.length > 3 && (
                            <Box sx={{
                                height: { xs: '32px', sm: '40px', md: '48px' },
                                width: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center'
                            }}>
                                {showNext && gridItems.firstRow.length > 3 && (
                                    <img
                                        onClick={() => this.handleNext(this.state.gridItems.secondRow, 'secondRow')}
                                        style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                                        src={rightmovearrow.default}
                                        alt="Next"
                                        data-test-id="scrollleftNextTwo"
                                    />
                                )}
                            </Box>
                        )}
                    </div>
                    </div>
            </Grid>
        )
    }

    renderImg = () => {
        const { gridItems } = this.state;
        return (
            <Box sx={{
                height: { xs: '32px', sm: '40px', md: '48px' },
                width: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center'
            }}>
                <img
                    onClick={() => this.handleNext(gridItems.firstRow.length, 'thirdRow')} style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                    src={rightmovearrow.default}
                    alt="Next"
                    data-test-id="scrollleftNextThree"
                />
            </Box>
        )
    }

    renderGrid = (index: number, item: any) => {
        return (
            <Grid item key={index}>
                {this.saferUserCard(item)}
            </Grid>
        )
    }
    thirdRowRender = () => {
        const { gridItems, currentIndex, itemsPerPage } = this.state;
        const visibleItems = gridItems.thirdRow && gridItems.thirdRow.slice(currentIndex.thirdRow, currentIndex.thirdRow + itemsPerPage.thirdRow);
        const showNext = currentIndex.thirdRow + itemsPerPage.thirdRow < gridItems?.thirdRow?.length;
        const showPrevious = currentIndex.thirdRow > 0;
        return (
            <Grid container sx={{ overflow: 'hidden', marginBottom: { xs: '10px', sm: '15px' }, marginTop: { xs: '10px', sm: '15px' } }}>
                <div style={{ flex: "auto", display: 'flex', gap: '10px' }}>
                    {showPrevious && (
                        <div style={{ height: '48px', width: '48px', alignSelf: 'center', position: 'relative', }}>
                        <Box sx={{
                            height: { xs: '32px', sm: '40px', md: '48px' }, position: 'relative', alignSelf: 'center',
                            width: { xs: '32px', sm: '40px', md: '48px' },
                        }}>
                            <img
                                onClick={() => this.handlePrevious(this.state.gridItems.thirdRow, 'thirdRow')}
                                style={{ cursor: 'pointer', position: 'relative', alignSelf: 'center', height: '100%', width: '100%' }}
                                src={backwardbutton.default}
                                alt="Previous"
                                data-test-id="scrollleftthree"
                            />
                        </Box>
                        </div>
                    )}
                    {visibleItems?.map((item: any, index: number) => {
                        return (
                            <Grid item key={index} xs={12} sm={6} md={4} lg={4} xl={4} sx={{ display: 'flex' }}
                                style={{ position: 'relative' }}>
                                {this.saferUserCard(item)}
                            </Grid>
                        )
                    })}
                    <div style={{ height: '48px', width: '48px', position: 'relative', alignSelf: 'center' }}>
                        {showNext && gridItems.firstRow.length > 3 &&
                            this.renderImg()
                        }
                    </div>
                </div>
            </Grid>
        )
    }

    renderAlcohalTab = () => {
        return (
            <Tab
                label="Alcohol"
                icon={
                    <Box sx={{ width: { xs: 16, sm: 20, md: 24 }, height: { xs: 16, sm: 20, md: 24 } }} component="img" src={alcoholTabImg.default} alt="alcohol" />
                }
                iconPosition="start"
                value="Alcohol"
                sx={{
                    margin: 0,
                    borderLeft: "0 !important",
                }}
            />
        )
    }

    renderCannabisTab = () => {
        return (
            <Tab
                label="Cannabis"
                icon={
                    <Box
                        component="img"
                        src={cannabisImg.default}
                        alt="Cannabis"
                        sx={{ width: { xs: 16, sm: 20, md: 24 }, height: { xs: 16, sm: 20, md: 24 } }}
                    />
                }
                iconPosition="start"

                value="Cannabis"
                sx={{ margin: 0 }}
            />
        )
    }

    renderUppers = () => {
        return (
            <Tab
                label="Uppers"
                icon={
                    <Box component="img" src={uppersTabImg.default} alt="uppers" sx={{ width: { xs: 16, sm: 20, md: 24 }, height: { xs: 16, sm: 20, md: 24 } }} />
                }
                iconPosition="start"

                value="Uppers"
                sx={{ margin: 0 }}
            />
        )
    }

    renderDowners = () => {
        return (
            <Tab
                label="Downers"
                icon={
                    <Box component="img" src={downersTabImg.default} alt="downers" sx={{ width: { xs: 16, sm: 20, md: 24 }, height: { xs: 16, sm: 20, md: 24 } }} />
                }
                iconPosition="start"
                value="Downers"

                sx={{ margin: 0 }}
            />
        )
    }

    renderTabbaco = () => {
        return (
            <Tab
                label="Tobacco or Nicotine"
                icon={
                    <Box component="img" src={tobaccoTabImg.default} alt="tobacco" sx={{ width: { xs: 16, sm: 20, md: 24 }, height: { xs: 16, sm: 20, md: 24 } }} />
                }
                iconPosition="start"
                value="Tobacco or Nicotine"

                sx={{ margin: 0 }}
            />
        )
    }

    HeaderFunction = () => {
        return (
            <>
               <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#0F4257",
            borderRadius: "16px",
            height: "88px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              height: "100%",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "clamp(25px, 5vw, 40px)",
                color: "#ffffff",
                fontFamily: "Josefin Sans",
                fontWeight: 300,
              }}
            >
              <Box
                component="span"
                sx={{
                  fontWeight: 700,
                  marginBottom: "4px",
                  color: "#EBFF00",
                  fontSize: "clamp(25px, 5vw, 40px)",
                  marginLeft: {
                    xs: '17px',
                    md: '48px',
                  },
                  fontFamily: "Josefin Sans",
                  lineHeight: "40px",
                }}
              >
                Well
              </Box>
              Spring
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
                fontSize: "clamp(14px, 2vw, 20px)",
              }}
            >
              Hey, {this.state.userName}
            </Typography>
          </Box>
          <Box
            component="img"
            src={menuIconImg.default}
            onClick={this.hamburgerMenu}
            alt="menuIcon"
            sx={{
              position: "absolute",
              top: "50%",
              right: "2.5%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
            }}
            data-test-id="hamburgerMenu"
          />
        </Grid>
            </>
        );
    }
    

    renderTabs = () => {
        const { activeTab } = this.state;
        return (
            <Tabs
                value={activeTab}
                onChange={(event, newValue) => this.handleTabChange(event, newValue)}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                    "& .MuiTabs-indicator": {
                        display: "none",
                    },
                    "& .MuiTab-root": {
                        color: "black",
                        fontSize: "16px",
                        fontWeight: 400,
                        fontFamily: "Chromatica-Regular",
                        marginRight: "4px",
                        padding: "8px 16px",
                        textTransform: "none",
                        "&.Mui-selected": {
                            bgcolor: "white",
                            color: "black",
                            zIndex: 1,
                            borderRadius: "10px 10px 0 0",
                            border: "1px solid #E5E5E5",
                            borderBottom: "0px",
                            margin: 0,
                            fontWeight: 700,
                        },
                        "&:not(.Mui-selected)": {
                            borderBottom: "1px solid #E5E5E5",
                            margin: 0,
                        },
                    },
                    "& .MuiTab-iconWrapper": {
                        marginRight: "15px",
                    },
                    "& .MuiTabScrollButton-root": {
                        display: "none",
                    },
                    "& .css-69z67c-MuiTabs-scroller": {
                        overflow: 'clip'
                    }
                }}
                data-test-id="selectedTab"
            >
                {this.state.categoryNameStore?.includes("Alcohol") &&
                    this.renderAlcohalTab()
                }
                {this.state.categoryNameStore?.includes("Cannabis") &&
                    this.renderCannabisTab()
                }
                {this.state.categoryNameStore?.includes("Tobacco or Nicotine") &&
                    this.renderTabbaco()
                }
                {this.state.categoryNameStore?.includes("Uppers") &&
                    this.renderUppers()
                }
                {this.state.categoryNameStore?.includes("Downers") &&
                    this.renderDowners()
                }
            </Tabs>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const popupMsg = this.state.addMoreCount < this.state.selectedCategory.length - this.state.selectedCategories.length;
        return (
            <>
            <Backdrop style={{ color: '#fff', zIndex: 12 }} open={this.state.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid
                item
                style={{
                    overflowY:'scroll',
                    backgroundColor: "#F0F0F0",
                    overflowX:'hidden',
                    height:'100%',
                    padding: "10px",
                }}
            >
                <Grid item
                    style={{
                        background: '#f0f0f0',
                    }}>
                    {this.HeaderFunction()}
                </Grid>
                <Grid
                    item
                    style={{
                        marginTop: '10px',
                        height: 'auto',
                        backgroundColor: 'white',
                        paddingBottom:'20px',marginBottom: '10px',
                        position: 'relative',
                        borderRadius: '16px',
                        minHeight: '100vh'
                    }}
                >
                    <Box
                      onClick={this.goBackToDashboard}
                        data-test-id="back-btn-goals" sx={{ paddingTop: '30px', cursor: 'pointer' }} >
                        <Box
                            sx={{ position: "absolute", paddingTop: '6px', left:{sm: 50, xs:20 } }}
                            onClick={this.goBackToDashboard} data-test-id="back-btn-dashboard"
                            component="img"
                            alt="Back"
                            src={arrow.default}
                        />
                        <Typography
                            data-test-id="gotoback"
                            onClick={this.goBackToDashboard}
                            sx={{
                                fontFamily: 'Chromatica-Bold',
                                cursor: "pointer",
                                color: '#252525',
                                position: "absolute",
                                left:{sm: 70, xs:40 },
                            }}>Back</Typography></Box>
                    <Box
                        sx={{
                            position: "relative",
                            top: "20px",
                            padding: { xs: '0px 20px 1px 20px', sm: '0 20px 1px 20px', md: '0 20px 1px 20px', lg: '0px 50px 1px 50px', xl: '0 60px 2px 60px', }
                        }}>
                        <Box
                            src={this.state.valueCheck?one.default:four.default}
                            alt="Logo Top Left"
                            component="img"
                            sx={{ width: { sm: '100px', md: '120px' }, height: { sm: '150px', md: '180px' }, objectFit: 'contain', }}
                        />
                        <Typography
                            sx={{
                                fontSize: '32px',
                                position: "absolute",
                                top: 0,
                                marginTop: '109px',
                                fontFamily: 'Josefin Sans',
                                fontWeight: '900',
                                color: '#00A7D1'
                            }}
                        >
                            Safer-Use Strategies</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                                sx={{
                                    width: "100%",
                                    mt: "3px",
                                    mb: "20px",                              
                                    zIndex: "2",
                                }}
                            >
                                {this.renderTabs()}
                                <Typography
                                    sx={{
                                        color: "#252525",
                                        fontFamily: "Chromatica-Regular",
                                        lineHeight: "24px",
                                        fontWeight: 400,
                                        fontSize: "16px",
                                        mt: "10px",
                                        zIndex: "2",
                                        backgroundColor: '#FFFFFF'
                                    }}
                                >
                                    For the <span style={{
                                        fontFamily: "Chromatica-Regular",
                                        fontWeight: '700', fontSize: '16px', color: '#252525'
                                    }}
                                    >Safer-Use Strategies</span> you want to use this week, click '<span style={{
                                        fontFamily: "Chromatica-Regular",
                                        fontWeight: '700', fontSize: '16px', color: '#252525'
                                    }}
                                    >+</span>'
                                </Typography>
                                <Box sx={{ marginTop: '24px' }}>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "16px",
                                                sm: "18px",
                                                md: "20px",
                                            },
                                            fontFamily: "Chromatica-Regular", fontWeight: "700",
                                            marginTop: "32px", marginBottom: "24px"
                                        }}
                                    >{this.state.headerItems.first}</Typography>

                                    {this.firstRowRender()}
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "16px",
                                                sm: "18px",
                                                md: "20px",
                                            },
                                            fontFamily: "Chromatica-Regular", fontWeight: "700",
                                            marginTop: "32px", marginBottom: "24px"
                                        }}
                                    >{this.state.headerItems.second}</Typography>
                                    {this.secondRowRender()}
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xs: "16px",
                                                sm: "18px",
                                                md: "20px",
                                            },
                                            fontFamily: "Chromatica-Regular", fontWeight: "700",
                                            marginTop: "32px", marginBottom: "24px"
                                        }}
                                    >{this.state.headerItems.third}</Typography>
                                    {this.thirdRowRender()}
                                </Box>
                            </Box>
                        </div>
                        {this.state.errorMsg.length > 0 && 
                        <Typography
                            sx={{
                                fontSize: "16px",
                                fontFamily: "Chromatica-Regular",
                                fontWeight: "500",
                                color: "rgb(235, 62, 62)"
                            }}
                        >
                            {this.state.errorMsg}
                        </Typography>}
                        <Button
                        onClick={this.goBackToSubstanceGoal} data-test-id="back-btn-substanceGoal"
                        variant="contained"
                        sx={{
                            ...buttonStyles,
                            fontSize: {
                                xs: '14px',
                                sm: '16px',
                                md: '18px',
                            },
                            lineHeight: {
                                xs: '18px',
                                sm: '20px',
                            },
                            marginBottom: {
                                xs: '30px',
                                sm: '45px',
                            },
                            width: {
                                xs: '80%',
                                sm: '60%',
                                md: '348px',
                            },
                            height: {
                                xs: '48px',
                                sm: '56px',
                            },
                        }}
                    >
                        <span style={{ marginRight: '5px' }}>Next</span>
                        <img src={arrowright.default} />
                    </Button>
                    </Box>
                    <Box
                        src={bottomBannerImg.default}
                        sx={{
                            width: '100%',
                            bottom: 0,
                            position: "absolute",
                            height: 'auto',
                            right: 0,
                            maxWidth: '600px',
                        }}
                        alt="image" component="img"
                    />
                </Grid>
                <Dialog open={this.state.GoalAchievedPopup}
                PaperProps={{
                    style: { width:391, borderRadius: 24,overflow:"hidden" }, 
                }}
            >
              
              <Confetti/>
                <DialogTitle>
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center', 
                        }}
                    >
                        <Box
                            sx={{
                                width: '80px',
                                height: '80px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                boxShadow: '0px 0px 18px 0px #F3A622',
                                marginBottom: '16px',
                                marginTop: "2rem", 
                                background: '#F3A622',
                                borderRadius: '50%',
                            }}
                        >
                            <div
                                style={{
                                  ...webStyles.submissonBox
                                }}
                            >
                                <img
                                    style={{ textAlign: 'center' }}
                                    src={award_a6.default}
                                    alt="award_award"
                                />
                            </div>
                        </Box>

                        <Typography style={webStyles.Congratulationsstyleinasfer}>
                            Congratulations!
                        </Typography>
                        <Typography sx={webStyles.countingsaferStyle}>
                            {popupMsg
                                ? "Your strategies were added"
                                : `You added a strategy to ${this.state.currentSelectedCategory}`
                            }
                        </Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Button
                                sx={{
                                    padding: "6px 10px",
                                    width: "281px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    border: "none",
                                    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                                data-test-id="strategyModal"
                                onClick={this.handleAddMoreStrategy.bind(this)}
                            >
                                <Typography sx={{
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"white",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: 1,
                                }}>
                                    Add Another Strategy <AddCircleOutlineIcon style={{ width:"24px",height:"24px" }}/>
                                </Typography>
                            </Button>
                            <Button
                                sx={{
                                    padding: "6px 10px",
                                    width: "281px",
                                    height: "44px",
                                    borderRadius: "8px",
                                    border: "none",
                                    cursor: "pointer",
                                    textTransform: "none"
                                }}
                                onClick={this.MoveToDashboard}
                                data-test-id="MoveToDashboard"
                            >
                                <Typography sx={{
                                    fontFamily:'Chromatica-Bold',
                                    fontSize: '16px',
                                    fontWeight: 700,
                                    lineHeight: '24px',
                                    color:"#3BA9D5",
                                    textAlign: "center",
                                }}>
                                    Done
                                </Typography>
                            </Button>
                        </Box>
                    </Box>
                </DialogTitle>
            </Dialog>
            </Grid>
            </>
        )
        // Customizable Area End
    }
}
// Customizable Area Start
const buttonStyles = {
    padding: {
        xs: '8px',
        sm: '10px',
        md: '12px',
    },
    borderRadius: "16px",
    boxShadow: 'none',
    background: "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
    textTransform: "none",
    backgroundColor: '#EFF8FB',
    fontWeight: 400,
    fontFamily: 'Chromatica-Bold',
    zIndex: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
};

const webStyles = {
    wholeGridStylegoals: {
        height: '88px',
        backgroundColor: '#0F4257',
        borderRadius: '16px',
        width: '98.5%',
        margin: '10px',
        overflowX: 'hidden',
        '@media (max-width: 486px)': {
            height: '70px',
            width: '95%',

        },

    },
    homeStylemenugoals: {
        mt: "3px",
        fontSize: "20px",
        lineHeight: "20px",
        color: "#FFFFFF",
        fontFamily: "Chromatica-Bold",
        fontWeight: 700,
    },
    gapMgoals: {
        gap: "20px",
        display: "flex",
        alignItems: "center",
    },
    parentWellgoals: {
        fontFamily: "Josefin Sans",
        color: "#ffffff",
        fontSize: "40px",
        fontWeight: 300,
        '@media (max-width: 486px)': {
            fontSize: "23px !important",
        }
    },
    wholedivStylegoals: {
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
        padding: "0 20px",
        height: "100%",
        '@media (max-width: 486px)': {
            padding: 0
        },
        '@media (max-width: 360px)': {
            padding: 0
        },
    },
    well__wellStylesgoals: {
        marginLeft: "40px",
        fontFamily: "Josefin Sans",
        lineHeight: "40px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#EBFF00",
        fontSize: "40px",
    },
    closeStyle:{
        position: 'absolute',
        right: 8,
        top: 8,
    },
  submissonBox:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  countingsaferStyle:{
    fontFamily:'Chromatica-bold' ,
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
  color:"#252525",
  marginBottom:"3rem",
  marginTop:"1.5rem"
},
Congratulationsstyleinasfer:{
    fontFamily: 'Josefin Sans',
    fontSize: '40px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-0.04em',
    color:'#00A7D1',
},
};
// Customizable Area End