import React from 'react';
import {
    Box,
    Typography,
    Radio, FormControlLabel,
    FormControl,
    IconButton,
    TextField,
    Button,
    Backdrop,
    CardActions,
    Card
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CannabisController, {
    Props,
} from './CannabisController.web';
import { info,  calendar, arrowright } from './assets';
import DatePickerCannabis from '../../landingpage/src/DatePickerCannabis.web';




export const configJSON = require("./config.js");


export default class Cannabis extends CannabisController {
    constructor(props: Props) {
        super(props);

    }
    render() {
         
        const doesMatchFirstFive = this.state?.optionsDate
            .slice(0, 5)
            .some((opt: any) => opt.date === this.state?.formattedDateInter);

        const shouldSelectEmptyDate =!doesMatchFirstFive && this.state?.formattedDateInter!=null && this.state?.formattedDateInter!='no';
        const valuesOptions = [{ value: 'Yes', label: `Yes` }, { value: 'No', label: `No` }]
        const { drinkCount } = this.state;
        const isValidLastWeekText = /^\d*$/.test(this.state.textdollars);
        const isValidThisWeekText = /^\d*$/.test(this.state.textdollarssecond);
  const isValidCanabieOtherText = /^[^a-zA-Z]*$/.test(this.state.OthersResponse || '');
        return (
            <>
            <Box sx={{ position: "relative" }}>
                
                <FormControl component="fieldset" style={{ width: '100%' }}>
                    <div style={{
                        display: 'flex',
                        gap: '10px',
                        justifyContent: 'start',
                        flexWrap: 'wrap',
                    }}>
                        {this.state.options?.map((option: any) => (
                            <Box
                                key={option.value}
                                sx={{
                                    width:'227px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.3s, border-color 0.3s', 
                                    border:this.borderHandle(option.value),
                                    backgroundColor: this.handlecolor(option.value),
                                    borderRadius: '32px',
                                    padding: '8px 12px',
                                    whiteSpace: 'nowrap',
                                    height: '40px',
                                    cursor: 'pointer'
                                }}
                            >
                                <FormControlLabel
                                    control={
                                        <Radio
                                            checked={this.state.selectedValue === option.value}     
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#00A7D1',
                                                },
                                                color: '#E3E3E3'
                                            }}
                                            onChange={this.handleChange}
                                            value={option.value}
                                        />
                                    }
                                    label={
                                        <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                    }
                                />
                            </Box>
                        ))}
                    </div>
                </FormControl>

                {this.state.selectedValue === 'Reduce how much' && (
                    <>
                        <Typography sx={{
                           
                            fontSize: '16px',
                            color: '#252525',
                            marginBottom: '20px',
                            width: '90%',
                            fontFamily: 'Chromatica-Regular',
                            marginTop: '20px',
                            padding: '10px',
                            borderRadius: '16px',
                            backgroundColor: '#F5F5F5'
                        }}>
                            <img
                                src={info.default}
                                alt="info"
                                style={{
                                    position: 'relative',
                                    height: '24px',
                                    top: '6px',
                                    marginRight: '3px',
                                    width: '24px'
                                }} />When you think about reducing, how would you want to measure that? Ounces, $$, hits? [choose one]</Typography>
                          <FormControl component="fieldset" style={{ width: '100%' }}>
                            <div style={{
                                display: 'flex',
                                gap: '10px',
                                justifyContent: 'start',
                                flexWrap: 'wrap',
                            }}>
                                {this.state.subOptions?.map((option: any) => (
                        
                                    <Box
                                        key={option.value}
                                        sx={{
                                            width:'124px',
                                            display: 'inline-flex',
                                            alignItems: 'center',
                                            transition: 'background-color 0.3s, border-color 0.3s',
                                            border: this.state.selectedValueSubOption === option.value ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                            backgroundColor: this.state.selectedValueSubOption === option.value ? '#EFF8FB' : 'transparent',
                                            borderRadius: '32px',
                                            padding: '8px 12px',
                                            whiteSpace: 'nowrap',
                                            height: '25px',
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <FormControlLabel
                                            control={
                                                <Radio
                                                    checked={this.state.selectedValueSubOption === option.value}
                                                    sx={{
                                                        '&.Mui-checked': {
                                                            color: '#00A7D1',
                                                        },
                                                        color: '#E3E3E3'
                                                    }}
                                                    onChange={this.handleChangeSubOption}
                                                    value={option.value}
                                                />
                                            }
                                            label={
                                                <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                            }
                                        />
                                    </Box>
                                ))}
                            </div>
                        </FormControl>

                        {this.state.selectedValueSubOption === 'Ounces' &&
                            <>
                        <Typography
                            sx={{
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                color: '#252525',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}
                        >
 Over the last week, how many Ounces did you have on a typical day when you use cannabis?
                        </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <IconButton
                              data-test-id="decrement"
                                onClick={this.handleDecrement}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="changedrink"
                                value={drinkCount}
                                onChange={this.handleInputChange}
                                style={{ 

                                    color: '#252525',
                                    height: '32px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    width: '51px',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    outline: 'none',

                                }}
                                 
                                onFocus={(e) => {
                                    if (e.target.style) {
                                        e.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                onBlur={(e) => {
                                    if (e.target.style) {
                                        e.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                            />
                            <IconButton
                            
                                onClick={this.handleIncrement}
                                data-test-id="increment"
                                sx={{
                                    borderRadius: '50%',
                                
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    },
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                }}
                            > <AddIcon />
                            </IconButton>
                        </div>
                        {this.state.drinkCount == 0 && this.state.CannabisButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
                        <Typography sx={{

                            color: '#252525',
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            marginTop: '30px',
                            marginBottom: '20px'
                    }}>What would you like to reduce that amount to this week?
                        </Typography>

                        <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                            <IconButton
                                onClick={this.handleDecrementSecond}
                                sx={{
                                    color: 'white',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="handleinput"
                                value={this.state.drinkCountSecond}
                                onChange={this.handleInputChangeSecond}
                                style={{
                                    fontSize: '16px',
                                    textAlign: 'center',
                                    color: '#252525',
                                    outline: 'none',
                                    fontFamily: 'Chromatica-Regular',
                                    width: '51px',
                                    borderRadius: '4px',                              
                                    height: '32px',
                                    border: '1px solid #E3E3E3',

                                }}
                                onFocus={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                }}
                                  onBlur={(event) => {
                                    if (event.target.style) {
                                        event.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                            
                            />
                             <IconButton
                                       
                                       sx={{
                                           height: '32px',
                                           backgroundColor: '#00A7D1',
                                           borderRadius: '50%',
                                           color: 'white',
                                           width: '32px',
                                           '&:hover': {
                                               backgroundColor: '#0096C7'
                                           },
                                          
                                       }}
                                       onClick={this.handleIncrementSecond}
                                   ><AddIcon />
                                       
                                   </IconButton>
                               </div>
                           </>
                       }
                       {this.state.selectedValueSubOption === '$$' &&
                           <>
                               <Typography
                                   sx={{
                                       fontFamily: 'Chromatica-Regular',
                                       fontSize: '16px',
                                       color: '#252525',
                                       marginTop: '30px',
                                       marginBottom: '20px'
                                   }}
                               >
                                   Over the last week, how many $$ did you have on a typical day when you use cannabis?
                               </Typography>
                            <textarea
                                id="input1"
                                onChange={this.handleDollars}
                                value={this.state.textdollars}
                                placeholder="Type here..."
                                style={{
                                    maxWidth: '286px',
                                    width: '100%',
                                    height: '58px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    padding: '10px 0',
                                    border: 'none',
                                    outline: 'none',
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #E1E1E1',
                                    resize: 'none', 
                                    minWidth:'50px'
                                }}
                            />
                             { 


  this.state.CannabisButtonCalled && (
    (this.state.textdollars?.length < 1 || !isValidLastWeekText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.textdollars?.length < 1 
            ? "Text field is required!" 
            : "Please enter valid Number!"
        }
      </p>
    )
  )
}
                               <Typography sx={{
                                   fontFamily: 'Chromatica-Regular',
                                   fontSize: '16px',
                                   color: '#252525',
                                   marginTop: '30px',
                                   marginBottom: '20px'
                               }}>
                                   What would you like to reduce that amount to this week?
                               </Typography>
                               <textarea
                                id="input2"
                                onChange={this.handleDollarsSecond}
                                value={this.state.textdollarssecond}
                                placeholder="Type here..."
                                style={{
                                    maxWidth: '286px',
                                    width: '100%',
                                    height: '58px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    padding: '10px 0',
                                    border: 'none',
                                    outline: 'none',
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #E1E1E1',
                                    resize: 'none', 
                                    minWidth:'50px'
                                }}
                            />
                             { 


  this.state.CannabisButtonCalled && (
    (this.state.textdollarssecond?.length < 1 || !isValidThisWeekText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.textdollarssecond?.length < 1 
            ? "Text field is required!" 
            : "Please enter valid Number!"
        }
      </p>
    )
  )
}
                           </>
                       }
                       {this.state.selectedValueSubOption === 'Hits' &&
                           <>
                               <Typography
                                   sx={{
                                       fontFamily: 'Chromatica-Regular',
                                       fontSize: '16px',
                                       color: '#252525',
                                       marginTop: '30px',
                                       marginBottom: '20px'
                                   }}
                               >
                                   Over the last week, how many Hits did you have on a typical day when you use cannabis?
                               </Typography>

                               <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                   <IconButton
                                       data-test-id="decrement"
                                       onClick={this.handleCannabisDecrement}
                                       sx={{
                                           borderRadius: '50%',
                                           backgroundColor: '#00A7D1',
                                           color: 'white',
                                           width: '32px',
                                           height: '32px',
                                           '&:hover': {
                                               backgroundColor: '#0096C7'
                                           }
                                       }}
                                   >
                                       <RemoveIcon />
                                   </IconButton>
                                   <input
                                       type="text"
                                       data-test-id="changedrink2"
                                       value={this.state.cannabisCount}
                                       onChange={this.handleInputCannabisChange}
                                       style={{
                                           width: '51px',
                                           border: '1px solid #E3E3E3',
                                           borderRadius: '4px',
                                           textAlign: 'center',
                                           outline: 'none',
                                           fontSize: '16px',
                                           height: '32px',
                                           fontFamily: 'Chromatica-Regular',
                                           color: '#252525',
                                       }}
                                       onBlur={(events) => {
                                           if (events.target.style) {
                                               events.target.style.border = '1px solid #E3E3E3';
                                           }
                                       }}
                                       onFocus={(e) => {
                                           if (e.target.style) {e.target.style.border = '1px solid #E3E3E3';
                                           }
                                       }}
                                   />
                                   <IconButton
                                       
                                       sx={{
                                           borderRadius: '50%',
                                           backgroundColor: '#00A7D1',
                                           width: '32px',
                                           height: '32px',
                                           color: 'white',   
                                           '&:hover': {
                                               backgroundColor: '#0096C7'
                                           }
                                       }}
                                       data-test-id="increment"
                                       onClick={this.handleIncrementCannabis}
                                   >
                                       <AddIcon /></IconButton>
                                   
                               </div>
                               {this.state.cannabisCount == 0 && this.state.CannabisButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
                               <Typography sx={{
                                     fontSize: '16px',
                                     color: '#252525',
                                     marginTop: '30px',
                                     marginBottom: '20px',
                                   fontFamily: 'Chromatica-Regular',
                               }}> What would you like to reduce that amount to this week?
                               </Typography>
                               <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                                   <IconButton
                                       onClick={this.handleCannabisSecondDec}
                                       sx={{
                                           color: 'white',
                                           width: '32px',
                                           borderRadius: '50%',
                                           backgroundColor: '#00A7D1',
                                           height: '32px',
                                           '&:hover': {
                                               backgroundColor: '#0096C7'
                                           }
                                       }}
                                   >
                                       <RemoveIcon />
                                   </IconButton>
                                   <input
                                       type="text"
                                       data-test-id="handleinput"
                                       value={this.state.CannabisSecond}
                                       onChange={this.handleInputCannabisSecond}
                                       style={{
                                           width: '51px',
                                           borderRadius: '4px',
                                           height: '32px',
                                           fontFamily: 'Chromatica-Regular',
                                           textAlign: 'center',
                                           outline: 'none',
                                           fontSize: '16px',
                                           color: '#252525',
                                           
                                           border: '1px solid #E3E3E3',

                                       }}
                                       onFocus={(eventFocus) => {
                                           if (eventFocus.target.style) {
                                               eventFocus.target.style.border = '1px solid #E3E3E3';
                                           }
                                       }}
                                       onBlur={(eventFocus) => {
                                           if (eventFocus.target.style) {
                                               eventFocus.target.style.border = '1px solid #E3E3E3';
                                           }
                                       }}

                                   />
                                   <IconButton
                                       onClick={this.handleCannabisSecondInc}
                                       sx={{
                                          
                                           borderRadius: '50%',
                                           '&:hover': {
                                               backgroundColor: '#0096C7'
                                           },
                                           height: '32px',
                                           backgroundColor: '#00A7D1',
                                           color: 'white',
                                           width: '32px',
                                       }}
                                   ><AddIcon />
                                   </IconButton>
                               </div>
                           </>
                       }
                   </>
               )}
               {this.state.selectedValue === 'Reduce how often' && (
                   <>
                       <Typography
                           sx={{
                               fontFamily: 'Chromatica-Regular',
                               fontSize: '16px',
                               color: '#252525',
                               marginTop: '30px',
                               marginBottom: '20px'
                           }}
                       >
                           Out of the past 7 days, how many days did you use cannabis?
                       </Typography>

                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                            <IconButton
                                onClick={this.handleDecrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="drinkcountoften"
                                value={this.state.drinkCountOften}
                                onChange={this.handleInputChangeOften}
                                style={{ 
                                    width: '51px',
                                    fontFamily: 'Chromatica-Regular',
                                    fontSize: '16px',
                                    color: '#252525',
                                    height: '32px',
                                    textAlign: 'center',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    outline: 'none',

                                }}
                                onFocus={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                                  onBlur={(eve) => {
                                    if (eve.target.style) {
                                        eve.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                            />
                            <IconButton
                                onClick={this.handleIncrementOften}
                                sx={{
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                        {this.state.drinkCountOften == 0 && this.state.CannabisButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Select value greater than 0</p>}
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
 Out of the NEXT 7 days, how many days do you want to use cannabis?
                        </Typography>

                        <div style={{ alignItems: 'center', gap: '10px', display: 'flex' }}>
                            <IconButton
                            data-test-id="secondoften1"
                                onClick={this.handleDecrementSecondOften}
                                sx={{
                                    color: 'white',
                                    width: '32px',
                                    borderRadius: '50%',
                                    backgroundColor: '#00A7D1',
                                    height: '32px',
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    }
                                }}
                            >
                                <RemoveIcon />
                            </IconButton>
                            <input
                                type="text"
                                data-test-id="text"
                                onFocus={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                                value={this.state.drinkCountSecondOften}
                                onBlur={(value) => {
                                    if (value.target.style) {
                                        value.target.style.border = '1px solid #E3E3E3';
                                    }
                                  }}
                                onChange={this.handleInputChangeSecondOften}
                                style={{
                                   
                                    fontSize: '16px',
                                    color: '#252525',
                                    width: '51px',
                                    fontFamily: 'Chromatica-Regular',
                                    height: '32px',
                                    border: '1px solid #E3E3E3',
                                    borderRadius: '4px',
                                    textAlign: 'center',
                                    outline: 'none',

                                }} 
                                  
                             
                            />
                            <IconButton
                            data-test-id="secondoften" onClick={this.handleIncrementSecondOften}
                                sx={{
                                  
                                    '&:hover': {
                                        backgroundColor: '#0096C7'
                                    },
                                    color: 'white',
                                    width: '32px',
                                    height: '32px',
                                    backgroundColor: '#00A7D1',
                                    borderRadius: '50%',
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </div>
                    </>
                )}
                {this.state.selectedValue === 'Safer/healthier' && (
                    <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                        }}>
                            Check out our list of safer use strategies <span data-test-id="cannabusValue" onClick={()=>this.handleSafer()}style={{ textDecoration: 'underline', fontFamily: 'Chromatica-Bold' }}>Here</span> to choose your goals for the next week.
                        </Typography>
                    </>
                )}
                {this.state.selectedValue === 'Other' && (
                    <div>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
  What do you want to see happen with your cannabis use?
                        </Typography>

                        <TextField
                         value={this.state.OthersResponse}
                            placeholder="Type here..."
                            multiline
                            InputProps={{
                                disableUnderline: false,
                                sx: {
                                    "&:after": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                    ".MuiInputBase-input": {
                                        paddingBottom: "5px",
                                    },
                                    "&:before": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                    "&:hover:not(.Mui-disabled):before": {
                                        borderBottom: "1px solid #E3E3E3",
                                    },
                                },
                            }}
                            inputProps={{
                                style: {
                                    fontSize: "16px",
                                    fontFamily: "Chromatica-Regular",
                                    fontWeight: 700,
                                    color: '#252525'
                                },
                                sx: {
                                    "&::placeholder": {
                                        fontSize: "16px",
                                        color: "#939393",
                                        fontFamily: "Chromatica-Bold",
                                        fontWeight: 700,
                                    },
                                },
                            }}
                            sx={{
                                fontWeight: 700,
                                lineHeight: "24px",
                                mt: "25px",
                                color: "#939393",
                                maxWidth: "816px",
                                fontSize: "16px",
                                fontFamily: "Chromatica-Regular",
                                minWidth:'50px'
                            }}
                            rows={5}
                            onChange={this.handleChangeOther}
                            variant="standard"
                            fullWidth
                        />
                        { 
  this.state.CannabisButtonCalled && (
    (this.state.OthersResponse?.length < 1 || isValidCanabieOtherText) && (
      <p style={{
        color: "#EB3E3E",
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        margin: '0', 
        marginTop: '5px'
      }}>
        { 
          this.state.OthersResponse?.length < 1 
            ? "Text field is required!" 
            : "Please enter valid text!"
        }
      </p>
    )
  )
}
                      
                    </div>
                )}

                {this.state.selectedValue === 'Stop completely' && (
                    <>
                        <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                                This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first.<br/> Are you sure you want to take a break from cannabis at this point?
                        </Typography>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            gap: '10px'
                        }}>
                            {valuesOptions?.map((option: any) => (
                                <Box
                                    key={option.value}
                                    sx={{
                                        display: 'inline-flex',
                                        border:this.handleOptionValue(option.value),
                                        backgroundColor: this.handleBGcolor(option.value),
                                        borderRadius: '32px',
                                        padding: '8px 12px',
                                        alignItems: 'center',
                                        transition: 'background-color 0.3s, border-color 0.3s',
                                        whiteSpace: 'nowrap',
                                        height: '40px',
                                        width:'100px',
                                        cursor: 'pointer'
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Radio
                                                checked={this.state.selectedValueOption === option.value}
                                                onChange={this.handleChangeOption}
                                                value={option.value}
                                                sx={{
                                                    '&.Mui-checked': {
                                                        color: '#00A7D1',
                                                    },
                                                    color: '#E3E3E3'
                                                }}
                                            />
                                        }
                                        label={
                                            <span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>
                                        }
                                    />
                                </Box>
                            ))}
                        </div>
                            {this.state.selectedValueOption === 'No' && (
                                <p style={{ 
                                        fontFamily: "Chromatica-Regular",
                                        fontWeight: 500,  color: "#EB3E3E",
                                        margin: "0",
                                        marginTop: "20px",
                                        marginBottom: "20px",
                                        fontSize: "16px",
                                        lineHeight: "24px", }}>
                                    Please select a new goal or click on "Yes" to continue.</p>)}

                        {this.state.selectedValueOption == "" && this.state.CannabisButtonCalled && <p style={{ color: "#EB3E3E",
                            fontFamily: "Chromatica-Regular",
                            fontSize: "16px",
                            fontWeight: 500,
                            lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select Yes or No. This field is required!</p>}

                   {this.state.selectedValueOption!=='No'?( <> <Typography sx={{
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            color: '#252525',
                            marginTop: '30px',
                            marginBottom: '20px'
                        }}>
                             Way to commit to taking a break from cannabis! What day this week do you want to start?
                        </Typography>
        
                        <FormControl component="fieldset" style={{ width: '100%' }}>
                                <div style={{
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    justifyContent: 'start',
                                    gap: '10px'
                                }}>
                                    {this.state.optionsDate?.map((option: any) => {
                                        const isSelected = this.state.formattedDateInter === option.date || (shouldSelectEmptyDate && option.date === '');
                                        return (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
        
                                                    border: isSelected ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                                   backgroundColor: isSelected ? '#EFF8FB' : 'transparent',
                                                    borderRadius: '32px',
                                                    padding: '8px 12px',
                                                    transition: 'background-color 0.3s, border-color 0.3s',
                                                    whiteSpace: 'wrap',
                                                }}
                                                key={option.value}
                                            >
                                                <FormControlLabel
                                                    label={<span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#00A7D1',
                                                                },
                                                                color: '#E3E3E3',
                                                            }}
                                                            onChange={() => this.handleChangeDates(option)}
                                                            value={option.value}
                                                            checked={isSelected}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        );
                                    })}
                                </div>
                            </FormControl>
    
                            {(this.state.valueOther === 'other' || shouldSelectEmptyDate) && (
                                <div style={{ marginTop: '20px', alignItems: 'center', display: 'flex', maxWidth: '286px',minWidth:'50px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                    data-test-id="popip"
                                        onClick={() => this.openPopUp(1)}
                                        type="text"
                                        placeholder="Choose Day"
                                        value={shouldSelectEmptyDate ? this.state.formattedDateInter : ''}
                                        style={{
                                            outline: 'none',
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                        readOnly
                                    />
                                    <img
                                      data-test-id="popimg"
                                        onClick={() => this.openPopUp(1)}
                                        src={calendar.default}
                                        alt="Calendar"
                                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    />
                                </div>
                            )} </>):''}
                         <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        padding: '25px 10px 15px 10px', width: '400px', display: 'flex', flexDirection: 'column',
                                        borderRadius: '16px',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>

                                        <DatePickerCannabis onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined} value={undefined} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '43px',
                                                border: '1px solid #00A7D1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px',
                                            }}

                                                onClick={() => this.closePopUp()}
                                                data-test-id="closebtn"
                                            > Back
                                            </Button>
                                            <Button style={{
                                                background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmCal()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )}
                            </Backdrop>
{this.state.formattedDateInter?.length < 3 && this.state.CannabisButtonCalled  && (
  <p
    style={{
      color: "#EB3E3E",
      margin: "0",
      fontSize: "16px",
      fontFamily: "Chromatica-Regular",
      marginTop: "5px",
      fontWeight: 500,
      lineHeight: "24px",
    }}
  >
    Date is required!
  </p>
)}
                    </>
                    
                )}  
                
            </Box>
            {this.state.selectedValueOption !== 'No'&&<Button
             data-test-id="addgoals"
            variant="contained"
            onClick={()=>{this.SaveCannabisData()}}
            sx={{
              padding: "10px",
              borderRadius: "16px",
              boxShadow: 'none',
              background:
                  "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
              
              backgroundColor: '#EFF8FB',
              fontSize: "18px",
              lineHeight: "20px",
              marginTop: '25px',
              marginBottom: '30px',
              cursor: 'pointer',
              maxWidth: '348px',
              height: '56px',
              minWidth:'50px',
              fontWeight: 400,
              fontFamily: 'Chromatica-Bold',
              textTransform: "none"}}>
                 <span style={{ marginRight: '5px' }}>Add Goal</span>
                 <img src={arrowright.default} />
             </Button>
        }
             </>
        )
    }
}
