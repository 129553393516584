import React from "react";
// Customizable Area Start
import { Box,Grid ,Tabs,Tab,IconButton,CardContent,Card,Button,Paper, CircularProgress, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles"; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {  arrow_Arrow_arrow_down, arrow_Arrow_up, award_a, award_a1, award_a2, award_a6, awrad_Award, flower_flower, hook_Hook, Menu_M, plus_Plus, wava_right, checkbox } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../../blocks/dashboard/assets/style.css";
import CloseIcon from '@mui/icons-material/Close';
import Confetti from "react-confetti";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

const WhiteBackgroundContainer = styled('div')({
  backgroundColor: 'white', 
  padding: "2rem", 
   marginLeft:"0.7rem",
   marginRight:"0.7rem",
  borderRadius:"16px" ,
  height:'-moz-available',
  minHeight:'fit-content',
  '@media (max-width: 486px)': { // Mobile devices
    padding:"19px !important",
   
      },                                                                                            
      '@media (max-width: 360px)': { // Separate media query for 360px
        padding: "10px !important",  // You can adjust padding here as needed
      },
})
 
// Customizable Area End
import GoalsInformationController, {
  Props,
  configJSON
} from "./GoalsInformationController.web";



export default class GoalsInformation extends GoalsInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  formatDateRangefor(card:any) {
    const createdAtDate = new Date(card.created_at);
    const startDate = createdAtDate;
    const endDate = new Date(createdAtDate);
    endDate.setDate(createdAtDate.getDate() + 7); 
    const formattedStartDate = `${startDate.getMonth() + 1}/${startDate.getDate()}`;
    const formattedEndDate = `${endDate.getMonth() + 1}/${endDate.getDate()}`;

    return `${formattedStartDate} - ${formattedEndDate}`;
}

    steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];
  
    icons = [
        <img src={award_a.default} alt="awrad_award" />,
        <img src={award_a1.default} alt="awrad_award" />,
        <img src={award_a2.default} alt="awrad_award" />,
    ];
    cardShowdatatwo = () => {
        return (
            <>
               
                <Grid container spacing={2}>

                    {this.state.getthisweekachiveData.map((card: any, index: any) => (
                        <Grid  item xs={12} sm={6}md={4} lg={3} key={index}
                        >
                            <Card variant="outlined" sx={{
                              ...webStyles.parentstyle,

                            }}>                            
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        <Box style={{...webStyles.cards }}>
                                            <Box style={{ ...webStyles.substyle }}>
                                                <span style={{ ...webStyles.flexd }}> 
                                                <img alt="Description" src={card?.icon?.url} style={{ width: "32px", height: '32px' }} />
                                                
                                
                                                </span>
                                            </Box>
                                            <Box> <span style={webStyles.drinksStyle}>  {card.title}</span> </Box>
                                            
                                        </Box>
                                    </Typography>
                                 <Box style={{marginTop: "20px"}}> <span style={{
                                    ...webStyles.drinksStyle2, marginLeft: '10px',marginRight:'10px',wordBreak:'break-word'
                                }}>  {card.responses.answer1}</span> </Box>
                                    <Typography variant="body2" color="textSecondary" style={{  ...webStyles.daterangeStyle,marginLeft: '10px'}}> {this.formatDateRangefor(card)}  </Typography>
                                    <div>
                                        {
                                            this.state.value === 1 ? card?.status === "pending" ?
                                                <Box
                                                    style={{
                                                        ...webStyles.flexboxStylenew
                                                    }}
                                                >
                                                    <Box>
                                                         <Button
                                                            sx={{
                                                                ...webStyles.stillworkingStyle
                                                            }}
                                                            onClick={this.handleOpenwell.bind(this, card.id)}
                                                        >
                                                            <Typography sx={webStyles.stillGoalStyle}>{configJSON.StillWorking}</Typography>
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <button
                                                            style={{ ...webStyles.AchievedGoalStyle, cursor: "pointer" }}
                                                            data-test-id="goalModal"
                                                            onClick={this.handleOpen.bind(this, card.id, card.responses.answer1, true)}
                                                        >
                                                            <Typography sx={webStyles.achievegoalstext}>
                                                                {configJSON.AchievedGoal}
                                                            </Typography>
                                                        </button>
                                                    </Box>
                                                    </Box>
                                                    : <Box sx={{marginTop: "1rem", textAlign: "center"}}>
                                                        <button className="activebtn">
                                                            <Typography sx={webStyles.achievegoalstext}>
                                                                <Box sx={{
                                                                    display: "flex", alignItems: "center", gap: 1, justifyContent: "center"
                                                                }}><img src={checkbox.default} width={16} height="16px" style={{
                                                                    borderRadius: "5px"
                                                                }} /> Goal Achieved</Box>
                                                            </Typography>
                                                        </button>
                                                    </Box>
                                                    : ""}
                                    </div>

                                </CardContent>
                                <Box sx={{ overflow: 'visible' }}>

                                    <IconButton
                                        style={{ position: 'absolute', right: 8, top: 16, cursor: 'pointer' }}
                                        onClick={this.handleIconClick.bind(this, card.id)}
                                    >
                                        <MoreVertIcon />
                                    </IconButton>
                                    {this.state.isPopupOpen === card.id && (
                                        <Paper
                                            className="popup-container"
                                            sx={{
                                             ...webStyles.elvs
                                            }}
                                            elevation={3}
                                        >
                                            <Typography
                                                style={{
                                                  ...webStyles.editstyle
                                                }}
                                                onClick={() => this.handleEditOtherGoal(card?.id)}
                                            >
                                                Edit
                                            </Typography>
                                            <Typography style={{ cursor: 'pointer', padding: '4px 0', ...webStyles.DeleteStyle }}
                                             onClick={() => this.handleDeleteOtherGoal(card?.id)}
                                            >
                                                Delete
                                            </Typography>
                                        </Paper>
                                    )}
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </>
        )
    }
    subTabsinalcohoandalltwo = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                   
                        <Box style={{marginTop:'1rem'}}>
                            {<>{this.state.getthisweekachiveData?.length>0?<this.cardShowdatatwo/>:<this.renderDataLastWeek/>}</>}
                      
                        </Box>
                    </Grid>
                </Grid>
    
        </>
    )
    }

    renderButtons = (index: any, value: any, cardId: number) => {
        if(value === 1) {
            return (
                this.state.valueLastWeek?.status?.[index] === "pending" ? <>
                    <Box
                        style={{
                            ...webStyles.flexboxStylenew
                        }}
                    >
                        <Box>
                                <Button
                                sx={{
                                    ...webStyles.stillworkingStyle
                                }}
                                onClick={this.handleOpenwell.bind(this, cardId)}
                            >
                                <Typography sx={webStyles.stillGoalStyle}>{configJSON.StillWorking}</Typography>
                            </Button>
                        </Box>
                        <Box>
                            <button
                                style={{ ...webStyles.AchievedGoalStyle, cursor: "pointer" }}
                                data-test-id="modalOpen"
                                onClick={this.handleOpen.bind(this, this.state.valueLastWeek.cardIds[index], this.state.valueLastWeek.descriptionAdded[index], false)}
                            >
                                <Typography sx={webStyles.achievegoalstext}>
                                    {configJSON.AchievedGoal}
                                </Typography>
                            </button>
                        </Box>
    
    
                    </Box>
                </> : <Box style={{ marginTop: '1rem', textAlign: "center" }}>
                            <button className="activebtn">
                                <Typography sx={webStyles.achievegoalstext}>
                                    <Box sx={{
                                        display: "flex", alignItems: "center", gap: 1, justifyContent: "center"
                                    }}><img src={checkbox.default} width={16} height="16px" style={{
                                        borderRadius: "5px"
                                    }} /> Goal Achieved</Box>
                                </Typography>
                            </button>
                        </Box>
            )
        } else {
            return "";
        }
    };
  
  
      cardThisWeek = (value: any) => {
        const resultData = value === 0 ? this.state.valueThisWeek : this.state.valueLastWeek;
        return (
            <>
                <Grid container spacing={2}>

                {resultData?.responses?.map((card: any, index: any = 0) => (                        
                   <Grid
                        item
                        key={index++}
                    >
                        <Card variant="outlined" style={{
                            position: 'relative', overflowY: 'auto',
                            height: this.state.value === 1 ? '' : '190px',marginTop:'10px',
                            borderRadius: '8px', maxWidth: '100%', width: '310px',

                        }} className="cardsizemaganethisweek">
                           
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem" }}>
                                        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem" }}>
                                            <Box style={{ width: '40px', borderRadius: '8px', border: "1px solid #E3E3E3" }}>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>
                                                 {this.state.selectValues===0 &&  <img src={awrad_Award.default} style={{ width: "18px", height: '28px' }} />}
                                                 {this.state.selectValues===1 &&<img src={flower_flower.default} style={{ width: "26px", height: '28px'  }} />}
                                                 {this.state.selectValues===2 && <img src={hook_Hook.default} style={{ width: "26px", height: '28px'  }} />}
                                                 {this.state.selectValues===3 && <img src={arrow_Arrow_up.default} style={{ width: "26px", height: '28px'  }} />}
                                                 {this.state.selectValues===4 && <img src={arrow_Arrow_arrow_down.default} style={{ width: "26px", height: '28px'  }} />}
                                                 {this.state.selectValues===5 && <img src={plus_Plus.default} style={{ width: "26px", height: '28px'  }} />}
                                               </span>
                                            </Box>
                                            <Box>
                                                <span style={webStyles.drinksStyle}>
                                                {this.state.selectValues===0&& 'Reduce Alcohol'}
                                                {this.state.selectValues===1&& 'Reduce Cannabis'}
                                                    {this.state.selectValues === 2 && (
                                                        <>
                                                            Reduce Tobacco
                                                            <br />
                                                            or Nicotine
                                                        </>
                                                    )}
                                                {this.state.selectValues===3&& 'Reduce Uppers'}
                                                {this.state.selectValues===4&& 'Reduce Downers'}
                                                    </span>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Typography>
                                <Typography variant="body2" color="textSecondary" style={{
                                    ...webStyles.drinksStyle2, marginLeft: '10px',marginRight:'10px',wordBreak:'break-word', marginTop: "20px"
                                }}>

                                    {resultData?.descriptionAdded?.[index]}
                                </Typography>

                                <Typography variant="body2" color="textSecondary" style={{
                                    ...webStyles.daterangeStyle, marginLeft: '10px', marginTop: "10px"
                                }}>{resultData?.dateRange?.[index]}
                                </Typography>
                                <div>
                                        {this.renderButtons(index, this.state.value, resultData?.cardIds?.[index])}
                                    </div>
                            </CardContent>
                            <div style={{ overflow: 'visible' }}>

                                <IconButton
                                    style={{ position: 'absolute', right: 8, top: 16, cursor: 'pointer' }}
                                    onClick={this.handleIconClick.bind(this, index)}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                {this.state.isPopupOpen === index && (
                                    <Paper
                                        className="popup-container"
                                        style={{
                                            padding: '1rem',
                                            zIndex: 1000,
                                            width: '130px',
                                            position: 'absolute',
                                            top: '40px',
                                            right: '10px',
                                            borderRadius: '8px',
                                        }}
                                        elevation={3}
                                    >
                                        <Typography
                                            style={{
                                                fontWeight: 400,
                                                lineHeight: '24px',
                                                color: '#252525',
                                                cursor: 'pointer',
                                                padding: '4px 0',
                                                fontFamily: 'Chromatica-Regular',
                                                fontSize: '16px',
                                            }}
                                            onClick={() => this.handleEditSubstanceGoal(value, index-1)}
                                        >
                                            Edit
                                        </Typography> <Typography style={{ cursor: 'pointer', padding: '4px 0', ...webStyles.DeleteStyle }}
                                        onClick={() => this.handleDeleteSubstanceGoal(value, index-1)}
                                        >
                                            Delete
                                        </Typography>
                                       
                                    </Paper>
                                )}
                            </div>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}
    renderData = () => {
        const resultData = this.state.value === 0 ? this.state.valueThisWeek : this.state.valueLastWeek;
        return (
            <div>
                  {!this.state.isLoading && resultData.responses?.length==0 ? (
                    <p
                    style={{
                        minHeight: "95px",
                        fontFamily: 'Josefin Sans',
                        fontSize: '40px',
                        fontWeight: 600,
                        color:"#00A7D1",
                        textAlign: "center",
                        marginTop: "30px"
                    }}
                    >No goals for this substance have been added</p>
            ) : (
                this.cardThisWeek(this.state.value) 
            )}
            </div>)}
                renderDataLastWeek = () => {
                    return !this.state.isLoading ? (                       
                                <p
                                style={{
                                    minHeight: "95px",
                                    fontFamily: 'Josefin Sans',
                                    fontSize: '40px',
                                    fontWeight: 600,
                                    color:"#00A7D1",
                                    textAlign: "center",
                                    marginTop: "30px",
                                }}
                                >No goals have been added</p>
                        ) : null }
                      
    renderData2 = () => {
        return (   
            <div>
                  {this.state.otherCategory?.length==0 ? (
                    <p
                    style={{   
                        minHeight: "95px",
                        color:"#00A7D1",
                        fontFamily: 'Josefin Sans',
                        fontSize: '40px',
                        fontWeight: 600,
                        textAlign: "center",
                        marginTop: "30px",
                    }}
                    >No goals for this substance have been added</p>
                ) : (
                this.othercategory()  )}</div>)}             
    othercategory = () => {
        return (
            <>
                <Grid container spacing={2}>
                    {this.state.otherCategory?.map((card: any, index: any = 0) => (        
                        <Grid
                            key={index++}
                            
                            item >
                            <Card variant="outlined" style={{
                                borderRadius: '8px', maxWidth: '100%', width: '310px',
                                position: 'relative', overflow: 'visible',
                                height: '128px',
                            }}>
                               
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        <Box style={{ alignItems: "center",display: "flex", justifyContent: "flex-start",  gap: "0.6rem" }}>
                                            <Box style={{ alignItems: "center", display: "flex", justifyContent: "flex-start", gap: "0.6rem" }}>
                                                <Box style={{border: "1px solid #E3E3E3", width: '40px', borderRadius: '8px',  }}>
                                                    <span style={{ alignItems: 'center',display: "flex", justifyContent: "center",  }}>
                                                      <img src={plus_Plus.default} style={{ width: "26px", height: '28px'  }} />
                                                   </span>
                                                </Box> <Box>
                                                    <span style={{...webStyles.drinksStyle}}>
                                                        {card}
                                                        </span>
                                                </Box></Box>
                                        </Box>
                                    </Typography>
                                </CardContent>
                                <div style={{ overflow: 'visible' }}>
                                    <IconButton
                                     onClick={this.handleIconClick.bind(this, index)}
                                        style={{ cursor: 'pointer' , position: 'absolute', right: 8, top: 16,}}>
                                        <MoreVertIcon />
                                    </IconButton>
                                    {this.state.isPopupOpen === index && (
                                        <Paper
                                            className="popup-container"
                                            style={{                                             
                                                right: '10px',
                                                padding: '1rem',
                                                width: '130px',
                                                position: 'absolute',
                                                top: '40px',
                                                zIndex: 1000,
                                                borderRadius: '8px',
                                            }}
                                            elevation={3}
                                        >
                                            <Typography
                                                style={{
                                                    fontWeight: 400,
                                                    lineHeight: '24px',
                                                    color: '#252525',
                                                    cursor: 'pointer',
                                                    padding: '4px 0',
                                                    fontFamily: 'Chromatica-Regular',
                                                    fontSize: '16px',
                                                }}
                                            >Edit
                                            </Typography>
                                            <Typography style={{ ...webStyles.DeleteStyle, cursor: 'pointer', padding: '4px 0', }}>
                                                Delete
                                            </Typography>
                                        </Paper>
                                    )}
                                </div></Card></Grid> ))} </Grid> </>
        )
    }
    subTabsinalcohoandall = () => {
        const getColor = (index: any, color: string) => {
            return this.state.selectValues === index ? color : 'transparent'
        }

        const checkCategory = (category: string) => {
            if(this.state.categories.length > 0) {
                return this.state.categories.includes(category) ? "" : "none !important";
            }
            return "none !important";
        };

        return (
            <>
               
                        <Box sx={{ borderColor: 'divider', backgroundColor: "#F7F7F7" }}>
                            <Box sx={{ width: '100%' }}>
                                <Grid container style={{ overflow:"hidden", display: "block" }}>
                                    <Grid item md={12} >
                                        <Tabs
                                            value={this.state.selectValues}
                                            onChange={this.handleChangethird}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            aria-label="substance tabs"
                                            sx={{

                                                overflow:"hidden",
                                                '@media (max-width: 360px)': {
                                                    overflowX: 'scroll',
                                                },
                                                '@media (max-width: 486px)': {
                                                    overflowX: 'scroll',
                                                },
                                                '@media (max-width: 820px)': {
                                                    overflowX: 'scroll',
                                                },
                                                '& .MuiTabs-scrollButtons': {
                                                    display: 'none',
                                                },
                                            }}
                                            TabIndicatorProps={{
                                                style: { display: 'none' }
                                            }}
                                        >
                                            <Tab
                                                icon={<img src={awrad_Award.default} style={{ width: "16px", height: '28px' }} />}
                                                label="Alcohol"
                                                onClick={() => this.getSubstanceGoals(this.state.value, 'Alcohol')}
                                                data-test-id="cannabis"
                                                iconPosition="start"
                                                sx={{
                                                    ...webStyles.tabs1,
                                                    backgroundColor: getColor(0, "white"),
                                                    borderTopColor: getColor(0, "E5E5E5"),
                                                    borderRightColor: getColor(0, "E5E5E5"),
                                                    transition: 'all 0.3s ease',
                                                    gap: "0.4rem",
                                                    display: checkCategory("Alcohol"),
                                                    padding: '8px 12px',
                                                    '&.Mui-selected': {
                                                        color: "#252525",
                                                        fontFamily: 'Chromatica-Bold',
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "20px",
                                                    },
                                                    '@media (max-width: 486px)': {
                                                        display: 'block', // Ensure tabs are visible on mobile
                                                        whiteSpace: 'nowrap', // Prevent wrapping and allow horizontal scrolling
                                                    },

                                                }}
                                            />
                                            <Tab
                                                icon={<img src={flower_flower.default} style={{ width: "37px", height: '37px', marginTop: "-6px" }} />}
                                                label="Cannabis"
                                                data-test-id="cannabis"
                                                onClick={() => this.getSubstanceGoals(this.state.value, 'Cannabis')}
                                                iconPosition="start"
                                                sx={{
                                                    ...webStyles.tabs2,
                                                    backgroundColor: getColor(1, "white"),
                                                    borderTopColor: getColor(1, "E5E5E5"),
                                                    borderRightColor: getColor(1, "E5E5E5"),
                                                    transition: 'all 0.3s ease',
                                                    gap: "0.4rem",
                                                    display: checkCategory("Cannabis"),
                                                    '&.Mui-selected': {
                                                        color: "#252525",
                                                        fontFamily: 'Chromatica-Bold',
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "20px",
                                                        borderBottom: "none",
                                                    }
                                                }}
                                            />
                                            <Tab

                                                icon={<img src={hook_Hook.default} style={{ width: "35px", height: '35px', marginTop: "-6px" }} />}
                                                label="Tobacco or Nicotine"
                                                onClick={() => this.getSubstanceGoals(this.state.value, 'tobaccoOrNicotine')}
                                                iconPosition="start"
                                                sx={{
                                                    ...webStyles.tab1,
                                                    backgroundColor: getColor(2, "white"),
                                                    borderTopColor: getColor(2, "E5E5E5"),
                                                    borderRightColor: getColor(2, "E5E5E5"),
                                                    transition: 'all 0.3s ease',
                                                    gap: "0.4rem",
                                                    display: checkCategory("tobaccoOrNicotine"),
                                                    '&.Mui-selected': {
                                                        color: "#252525",
                                                        fontFamily: 'Chromatica-Bold',
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "20px",
                                                    }
                                                }}
                                            />
                                            <Tab
                                                icon={<img src={arrow_Arrow_up.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                                                label="Uppers"
                                                onClick={() => this.getSubstanceGoals(this.state.value, 'uppers')}
                                                iconPosition="start"
                                                sx={{
                                                    ...webStyles.tab2,
                                                    backgroundColor: getColor(3, "white"),
                                                    borderTopColor: getColor(3, "E5E5E5"),
                                                    borderRightColor: getColor(3, "E5E5E5"),
                                                    transition: 'all 0.3s ease',
                                                    gap: "0.4rem",
                                                    display: checkCategory("uppers"),
                                                    '&.Mui-selected': {
                                                        color: "#252525",
                                                        fontFamily: 'Chromatica-Bold',
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "20px",
                                                    }
                                                }}
                                            />
                                            <Tab
                                                icon={<img src={arrow_Arrow_arrow_down.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                                                label="Downers"
                                                onClick={() => this.getSubstanceGoals(this.state.value, 'downers')}
                                                iconPosition="start"
                                                sx={{
                                                    ...webStyles.tab3,
                                                    backgroundColor: getColor(4, "white"),
                                                    borderTopColor: getColor(4, "E5E5E5"),
                                                    borderRightColor: getColor(4, "E5E5E5"),
                                                    transition: 'all 0.3s ease',
                                                    gap: "0.4rem",
                                                    display: checkCategory("downers"),
                                                    '&.Mui-selected': {
                                                        color: "#252525",
                                                        fontFamily: 'Chromatica-Bold',
                                                        fontSize: "16px",
                                                        fontWeight: 700,
                                                        lineHeight: "20px",
                                                    }
                                                }}
                                            />

                                        </Tabs>
                                    </Grid>
                                </Grid>
                            </Box>


                        </Box>
                      
                        <Box style={{ marginTop:'1rem' }}>
                       {this.state.selectValues!==5 &&<this.renderData/> }  
                       {this.state.selectValues===5 && <this.renderData2/>}                        
                        </Box>
                  
  
        </>
    )
   }
HeaderFunction = () =>{
    return (
        <>
         <Grid
          item
          sx={{
           ...webStyles.wholeGridStylegoals
          }}
        >
       <Box>
       <Box
                        sx={{                  
                          ...webStyles.wholedivStylegoals}}>
                        <Box
                            sx={{
                              ...webStyles.gapMgoals
                            }}
                        >
                            <Box
                                sx={{
                                  ...webStyles.parentWellgoals
                                }} >
                                <Box
                                    component="span"
                                    sx={{
                                      ...webStyles.well__wellStylesgoals,
                                        marginLeft: {
                                            xs: '17px !important', 
                                            md: '48px !important',
                                          },
                                    }}
                                    className="well__wellStylesgoals"
                                >
                                    Well
                                </Box>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                  ...webStyles.homeStylemenugoals,
                                   
                                }}
                            >
                                Hey, {this.state.userName}</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenunext() }}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "50px",
                                "@media (max-width: 530px)": {
                                    marginRight: "20px !important"},
                                "@media (min-width: 531px) and (max-width:1200px)": {
                                    marginRight: "30px !important"}
                            }}
                            alt="menuIcon"      
                        />
                    </Box>
       </Box>
        </Grid>
        </>
    )
}
    tabThisWeekfunction = () =>{
    return (
        <>
            <Box>
               
                <Box>
                    <Box sx={{ width: '100%',marginTop:'1.5rem', minHeight: { md: "40.5vh", sm: "58vh", xs: "53vh" } }} >
                    <Grid container style={{overflow:"auto"}} >
                    <Grid item md={12} >
                        <Tabs
                           data-test-id="handleTab"
                            value={this.state.values}
                            onChange={this.handleChangesecond}
                            aria-label="basic tabs example"

                            TabIndicatorProps={{
                                style: {
                                    position: 'absolute',
                                    height: '2px',
                                    bottom: '0px',
                                    width: '100%',
                                    border:"none",
                                     backgroundColor: 'white', 
                                     overflow: 'scroll',
                                },
                            }}

                                    sx={{
                                        overflow: "auto !important",
                                        '@media (max-width: 360px)': {
                                            overflowX: 'scroll',
                                        },
                                        '@media (max-width: 486px)': {
                                            overflowX: 'scroll',
                                        },
                                        '@media (max-width: 820px)': {
                                            overflowX: 'scroll',
                                        },
                                    }}

                           
                        >
                             <Tab
                                label="Substance-Related Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#A0A0A0", 
                                    textTransform: "none",
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 0 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                    }
                                }}
                            />
                           
                          
                        
                          <Tab
                                label="Other Life Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400, borderBottom:"1px solid #E5E5E5",
                                    lineHeight: "20px",
                                    textTransform: "none",
                                    color: "#A0A0A0", 
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 1 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px", // Style for the selected tab
                                    }
                                }}
                            />
                         

                        </Tabs>
                        </Grid>
                        </Grid>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.values === 0 && <this.subTabsinalcohoandall/>}
                            {this.state.values === 1 && <this.subTabsinalcohoandalltwo/>}
                        </Box>
                    </Box>
                </Box>

     
                
        </Box>
        </>
    )
   }
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
  <div>
    <Backdrop style={{ color: '#fff', zIndex: 10 }} open={this.state.isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  <Grid  item
        style={{
          background: '#f0f0f0',
          width:'100%',
        }}>
 {this.HeaderFunction()}
  </Grid>
 
        <WhiteBackgroundContainer style={{backgroundColor:'white',height:'auto',minHeight:'fit-content'}}>
            <img src={wava_right.default} style={{position:"absolute",top:"126px",right:0}} className="imagehide"/>
                <Box data-test-id="backBtn" sx={{width: "fit-content", display:"flex",justifyContent:'flex-start',alignItems:"center",gap:'0.5rem', padding: "1rem 0",position: "relative", zIndex: 2 }}
            onClick={() => this.goToBackDashboard()}
                >
                    <KeyboardBackspaceIcon sx={{cursor: "pointer"}} />
                     <Typography style={webStyles.backStyle}>Back</Typography>
                </Box>
               <Box style={{marginTop:"0.9rem"}}>
               <Box style={webStyles.addGoalsStyle}>
                    <Box>
                         <Typography sx={webStyles.goalsStyleleft}>Goals</Typography>
                    </Box>
                    <Box sx={{position: "relative", zIndex: 2}}>
                        <button
                        data-test-id="subCatBtn"
                        style={{
                            padding: "0.5rem",     
                            width: "194px",          
                            borderRadius: "8px",     
                            border: "none",    
                            cursor: "pointer",       
                            background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                        }}
                        onClick={() => this.goToGoals()}
                      
                        >
                           <div style={webStyles.iconflex}>
                           <Typography sx={webStyles.addGoalsStyleAdd}>Add More Goals</Typography>
                           <AddCircleOutlineIcon style={{width:"16px",height:"16px",color:"#DADADA"}}/>
                           </div>
                        </button>
                     </Box>
                </Box>
               </Box>

                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                           sx={{ ...webStyles.basicStyle}}
                           aria-label="basic tabs example"
                            onChange={this.handleChange}
                            TabIndicatorProps={{
                                style: {
                                     backgroundColor: '#00A7D1',
                                     marginTop:"-10px" // Custom indicator color
                                },
                            }}
                            value={this.state.value}
                         
                        >
                            <Tab
                                label="This week"
                                sx={{
                                  ...webStyles.selectStyleMUI
                                }}
                            />
                            <Tab
                                label="Last week"
                                sx={{
                                  ...webStyles.lastWeekStyleMui
                                }}
                            />

                        </Tabs>
                   <Box style={{marginTop:"1rem"}}>
                   <Box>
                    <Typography sx={webStyles.Statisticsstyle}>
                        Statistics
                    </Typography>
                </Box>
                <Box style={{ marginTop: "1rem" }}>
                    <Typography sx={webStyles.youaidyourStyle}>{this.state.value === 0 ? 'This week, you said your Goals are to:' : 'Last week, you said your Goals were to:'}</Typography>
                </Box>
                      
                    </Box>  
                    <Box sx={{ height:'auto',minHeight:'90vh' }}>
                            {this.state.value === 0 && <this.tabThisWeekfunction/>}
                            {this.state.value === 1 && <this.tabThisWeekfunction/>}
                           
                        </Box>
                    </Box>
                </Box>

              
             
          
          
                </WhiteBackgroundContainer>
        <Dialog onClose={this.handleClose} open={this.state.openCongrates}
                PaperProps={{
                    style: { borderRadius: 24,overflow: "hidden",boxShadow:"none" }, // Add border radius here
                }}
            >
            <Confetti/>
                <DialogTitle>
                    <Box
                        sx={{
                          ...webStyles.dialogStyleparent
                        }}
                    >
                        <Box
                            sx={{
                            ...webStyles.dialogContentStyle
                            }}
                        >
                            <div
                                style={{
                                ...webStyles.flex__box

                                }}
                            >
                                <img
                                    src={award_a6.default}
                                    alt="award_award"
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                        </Box>

                        <Typography sx={webStyles.Congratulationsstyle}>
                            Congratulations!
                        </Typography>
                        <Typography sx={webStyles.countingStyle}>You achieved your goal to {this.state.goalText}.</Typography>
                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={this.handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>
            <Dialog onClose={this.handleClosewell} open={this.state.openWell}
                PaperProps={{
                    style: { borderRadius: 24,overflow:"hidden",boxShadow:"none" },
                    background: "#0F4257"// Add border radius here
                }}
            >
                <DialogTitle sx={{ background: "#0F4257" }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', // To center text as well
                            width: '100%',
                        }}
                    >

                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                            <Typography sx={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 700,
                                fontSize: '40px',
                                lineHeight: '40px',
                                letterSpacing: '-0.04em',
                                color: "#EBFF00",
                                marginTop: "1.2rem",
                            }}>Well</Typography>
                            <Typography style={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 700,
                                fontSize: '40px',
                                lineHeight: '40px',
                                letterSpacing: '-0.04em',
                                color: "white",
                                marginTop: "1.2rem",
                            }}>Spring</Typography>
                        </div>
                        <Typography sx={{ ...webStyles.Congratulationsstyleone, marginTop: "1.2rem" }}>
                            Way to keep going! Do you want to make any<br />
                            changes in striving to meet this goal?!
                        </Typography>

                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.8rem",
                            marginTop: "1rem",
                            marginBottom: "2rem"
                        }}
                    >
                        <Box>
                            <Button
                                sx={{ ...webStyles.Styleyes, cursor: "pointer", width: { sm: "169px", xs: "100px" } }}
                                onClick={this.handleButtonClick} // Hide section on button click
                            >
                                <Typography sx={{ ...webStyles.achievegoalstext, color: "white", fontSize: "18px" }}>
                                    Yes
                                </Typography>
                            </Button>
                        </Box>

                        <Box>
                            <Button
                                sx={{
                                    width: { sm: "169px", xs: "100px" },
                                    borderRadius: "8px",
                                    padding: "0.6rem",
                                    textTransform: "none",
                                    border: "1px solid #ffffff",
                                    cursor: "pointer",
                                }}
                                onClick={this.handleClosewell}

                            >
                                <Typography sx={webStyles.noStyle} >No</Typography>
                            </Button>
                        </Box>


                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={this.handleClosewell}
                        sx={{
                            color: "white",
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>
          
        
  </div>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    wholedivStylegoals:{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
        height: "100%",
        '@media (max-width: 486px)': { // Mobile devices
        padding:0
          },
          '@media (max-width: 360px)': { // Mobile devices
            padding:0
              },
      },
      wholeGridStylegoals:{
        height: '88px',
        backgroundColor: '#0F4257',
        borderRadius: '16px',
        margin: '10px',
        overflowX:'hidden',
       
          '@media (max-width: 820px)': { // Mobile devices
            height: '88px', // Further reduce height for mobile
            overflow:'hidden', // Adjust width for mobile
           
          },
        
      },
      well__wellStylesgoals:{
        fontFamily: "Josefin Sans",
        lineHeight: "40px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#EBFF00",
        fontSize: "clamp(25px, 5vw, 40px) !important",
      
      
       },
       homeStylemenugoals:{
        mt: "3px",
        fontSize: "clamp(14px, 2vw, 20px)",
        lineHeight: "20px",
        color: "#FFFFFF",
        fontFamily: "Chromatica-Bold",
        fontWeight: 700,
      },
      gapMgoals:{
        gap: "20px",
        display: "flex",
        alignItems: "center",
      },
      parentWellgoals:{
        fontFamily: "Josefin Sans",
        color: "#ffffff",
        fontSize: "clamp(25px, 5vw, 40px)",
        fontWeight: 300,
      },
      tabs3:{
        display: 'flex',
        flexDirection: 'row',
        fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
        textTransform: "none",
        color: "$252525",
        alignItems: "center",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    tabs2:{
        display: 'flex',
        color: "$252525",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: 'flex-start',
        fontFamily:'Chromatica-Bold' ,
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px', borderBottom:"1px solid #E5E5E5",
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 8px",
    },
    countingStyle:{
        fontFamily:'Chromatica-Regular' ,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '24px',
      color:"#252525",
      marginBottom:"3rem",
      marginTop:"1rem"
    },
    drinksStyle:{
    fontFamily:'Chromatica-Bold' ,
 marginRight:'10px',
    fontSize: '16px',
    fontWeight: 700,
    color:'#252525',
    lineHeight: '24px'
    },
    drinksStyle2:{
        marginRight:'10px',wordBreak:'break-word',
        fontFamily:'Chromatica-Regular' ,
        fontSize: '16px',
        fontWeight: 700,
        color:'#252525',
        lineHeight: '24px'
        },
    noStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        color:"white"
    },
    topParentGrid:{
        padding: '10px',
        backgroundColor: '#f0f0f0',
    },
    parentBox:{
        right: 0,
        marginRight: '30px',
        position: "absolute",
        top: 0,

    },
    elvs:{
        width: '130px',
        position: 'absolute',
        top: '40px',
        right: '10px',
        padding: '1rem',
        zIndex: 1000,
        borderRadius: '8px',
    },
    Congratulationsstyleone:{
        fontFamily: 'Chromatica-Regular',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        color:'white'
    },
    cards:{
         display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem"
    },
    tab3:{
        display: 'flex',
        flexDirection: 'row',
        color: "$252525",
        alignItems: "center",
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    DeleteStyle:{
        fontFamily: 'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color:'#252525'
    },
    editstyle:{
        cursor: 'pointer',
        padding: '4px 0',
        fontFamily: 'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        color: '#252525',
    },
    heyTomStyle:{
        position: "absolute",
        top: "20px",
        left: "260px",
        fontSize: "17px",
        width: '180px',
        color: "white",
        fontFamily: 'Chromatica-Bold',
    },
    SpringStyle:{
        fontSize: "40px", fontWeight: "normal", marginLeft: "5px" 
    },
    wellStyle:{
        fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px"
    },
    childGrid:{
        color: "#ffffff",
        fontFamily: 'Josefin Sans',
        position: "absolute",
        top: "36px",
        left: '48px',
        width:'95%'
    },
    daterangeStyle:{
        marginTop: 8,
        color: "#00A7D1",
        fontFamily: 'Chromatica-Bold',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
    },

    parentGrid:{
        height: '88px',
        backgroundColor: '#0F4257',
        margin: '10px',
        borderRadius: '16px',
        width: '99%',
    },
    tab2:{
        display: 'flex',
        flexDirection: 'row',
        color: "$252525",
        alignItems: "center",
        justifyContent: 'flex-start',
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    youaidyourStyle:{
        fontFamily:'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color:"#252525"

    },
    Congratulationsstyle:{
        fontFamily: 'Josefin Sans',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        color:'#00A7D1',
    },
  
    substyle:{
        width: '40px', borderRadius: '8px', border: "1px solid #E3E3E3"
    },
    Statisticsstyle:{
        fontFamily:'Chromatica-Regular' ,
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        color:"#000000"
    },
    tab1:{
        display: 'flex',
        color: "$252525",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: "center",
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    basicStyle:{
        borderBottom: 2, borderColor: 'divider',color:"#E3E3E3" 
    },
    parentstyle:{
        position: 'relative', overflow: 'visible',
        borderRadius: '8px', maxWidth: '100%', width: '310px',
    },
    iconflex:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:'0.5rem'
    },
    buttonStyleadd:{
        width: "194px",          // Fixed width of 194px
    padding:"0.5rem",              // Gap between children elements (works in flexbox or grid)
        borderRadius: "8px 0 0 0",  // Border-radius (top-left rounded)
        opacity: 0
    },
    goalsStyleleft:{
        fontFamily: "Josefin Sans",
        fontSize: "32px",
        fontWeight: 600, // Numeric value for font-weight
        lineHeight: "32px",
        color:"#00A7D1"
    },
    addGoalsStyleAdd:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "24px",
        color:'#ffffff',
        cursor: "pointer",
    },
    flexd:{
        display: "flex", justifyContent: "center", alignItems: 'center'
    },
    Styleyes:{
        padding: "0.8rem",     
        borderRadius: "8px",     
        border: "none",       
        color:"white",    
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
    },
    addGoalsStyle:{
        display:"flex",justifyContent:'space-between',alignItems:"center",
     
    },
    backStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "20px",
        cursor: "pointer",
    },
    flexproperty: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
  },
    testStyleforHeyTom: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#ffffff",
        marginLeft: "1.3rem"
    },
    HeyTomStyle: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#EBFF00"
    },
    tabs1:{
        gap: "0.4rem",
        padding: '8px 12px', 
        display: 'flex',
        alignItems: "center",
          flexDirection: 'row',                                   
          fontFamily:'Chromatica-Bold' ,
          textTransform: "none",

          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '28px',
          color: "$252525",
          justifyContent: 'flex-start',
          borderTop: '1px solid #E5E5E5',
          borderRight: '1px solid #E5E5E5',
          borderRadius: "1px 8px 0px 0px",
    },
    selectStyleMUI:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#A0A0A0", // Text color
        textTransform: "none",
        '&.Mui-selected': {
            color: "#000",
            fontFamily:'Chromatica-Bold' ,
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
        },
    },
    lastWeekStyleMui:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 400,  
        lineHeight: "20px",

        textTransform: "none",
        color: "#A0A0A0", // Text color
        '&.Mui-selected': {
            color: "#000",
            fontFamily:'Chromatica-Bold' ,
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px", // Style for the selected tab
        }
    },
  threebythreeStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    

  },
  flex__box:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  labelstylestepper:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
  dialogStyleparent:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center', // To center text as well
    width: '100%',
  },
  descriptionStyle:{
    fontFamily:'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#252525',
    marginTop: "10px"
  },
YourStyle:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1"

},
stillGoalStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"#1D5E79",
    
 },
 flexboxStylenew:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "0.8rem",
    marginTop: "1rem",
 },
 AchievedGoalStyle:{
    padding: "6px 10px",     
    width: "auto",         
    borderRadius: "8px",     
    border: "none",           
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
  },
 achievegoalstext:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"white",
    textAlign:'center'
    
 },
 dialogContentStyle:{
    width: '80px',
    height: '80px',
    boxShadow: '0px 0px 18px 0px #F3A622',
    background: '#F3A622',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
    marginTop: "2rem", // Adds spacing below the image
 },

addGoalsStylebox:{
    display:"flex",justifyContent:'space-between',alignItems:"center"
},
stillworkingStyle:{
    width: "auto",
    borderRadius: "8px",
    padding: "6px 10px",
    textTransform: "none",
    border: "1px solid #1D5E79",
    cursor: "pointer",
}
};

// Customizable Area End