import React from 'react';
import {
    Grid,
    Button,
    Box,
    Checkbox,
    Typography,
    Backdrop,
    CircularProgress,
} from "@mui/material";

import CategoriessubController, {
    Props,
} from './CategoriessubController.web';
import { arrow, bottomBannerImg,cannabis,downers,uppers,tabacco,imagealcohal,backwardbutton, arrowright, one, rightmovearrow, hamburgermenu, others, two } from './assets';


export const configJSON = require("./config.js");


export default class Categoriessubcategories extends CategoriessubController {
    constructor(props: Props) {
        super(props);

    }

    render() {
      const images = [
        imagealcohal.default,
        cannabis.default,      
        tabacco.default,        
        uppers.default,         
        downers.default,
        // others.default
      ]
        const { scrollOffset, visibleItemCount } = this.state;
        const visibleItems = this.state.gridItems.slice(scrollOffset, scrollOffset + visibleItemCount);
        const { gridItems,  getCategoryComplete} = this.state;
        return (
          <>
          <Backdrop style={{ color: '#fff', zIndex: 10 }} open={this.state.isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
            <Grid
            item
            style={{
             overflowY:'scroll',backgroundColor: "#F0F0F0", height:'100%', padding: "10px",
            }}
          >
            <Grid
              item
              style={{
                height: '88px',
                backgroundColor: '#0F4257',
                borderRadius: '16px', 
                position: "relative",
              }}
            >
            <Box
             sx={{
               display: "flex",
               alignItems: "center",
               gap: "20px",
               alignContent: "center",
              height: "100%",
            }}
            >
              <Box
                sx={{
                  fontFamily: "Josefin Sans",
                  color: "#ffffff",
                  fontWeight: 300,
                  fontSize: "clamp(25px, 5vw, 40px)",
                }}
              >
                <Box
                component="span"
                sx={{
                  marginBottom: "4px",
                  fontWeight: 700,
                  color: "#EBFF00",
                  fontSize: "clamp(25px, 5vw, 40px)",
                  marginLeft: {
                    md: '48px',
                    xs: '17px', 
                  },
                  lineHeight: "40px",
                  fontFamily: "Josefin Sans",
                }}
                >
                  Well
                </Box>
                Spring
              </Box>
              <Typography
               style={webStyle.name}
              >
                Hey, {this.state.userName}
              </Typography>
            </Box>
            <Box
              onClick={()=>{this.hamburgerPage()}}
              component="img"
              src={hamburgermenu.default}
              alt="menuIcon"
              sx={{
              position: "absolute",
              top: "50%",
              right: "2.5%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
              }}
            />
            </Grid>
          
              <Grid
                item
                style={{...webStyle.gridItem, position: 'relative'}}>
              
                <Box
                  sx={{
                    position: "relative",
                    top: 20,
                    height:'auto',
                    minHeight:'100vh',
                    marginLeft: {
                      sm: '50px',
                      xs:'20px' },
                      marginRight:{
                          sm: '0px',
                          xs:'20px' 
                      },
                  }}
                >
                  <Box
                    component="img"
                    src={ two.default}
                    alt="Logo Top Left"
                    sx={{
                      width:'90px',
                      height: '180px',
                      position: 'relative',
                      left: '13px'
                    }}
                  />
                  <Box
                  component="img"
                  src={bottomBannerImg.default}
                  alt="Logo"
                  sx={{
                    position: "absolute",
                    bottom: 19,
                    right: 0,
                    width: '100%',
                    height: 'auto',
                    maxWidth: '600px',
                    display:{
                      md:'block',
                      xs:'none',
                  }
                  }}
                />
                  <Typography
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      marginTop: '100px',
                      fontFamily: 'Josefin Sans',
                      fontWeight: '900',
                      fontSize: '32px',
                      color: '#00A7D1'
                    }}
                  >
                  {this.state.valueCheck? 'Safer-use Strategies' : 'Introduction'}
                  </Typography>
                 {this.state.valueCheck?'': 
                  <Typography sx={{
                    fontFamily: 'Chromatica-Regular',
                    fontSize: '16px',
                    color: '#252525',
                    marginBottom:'20px'
                  }}>If we have an idea of your substance use patterns, we can better meet your needs. We're here to meet you where you're at and help you get to where you want to go. So, this is a judgment-free zone where you can be real.</Typography>
                  }<Typography sx={{
                    fontFamily: 'Chromatica-Bold',
                    paddingTop: '10px',
                    fontSize: '20px',
                    color: '#252525'
                }}>Choose all substances relevant to you:</Typography>

<div style={{ display: 'flex', alignItems: 'center',width:'100%' }}>
  <div style={{height: '48px', width: '48px', minWidth:'48px',maxWidth:'48px'}}>
  {scrollOffset > 0 && (
          <img
            onClick={this.handleScrollLeft}
            style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' }}
            src={backwardbutton.default}
            alt="Previous"
          />
        )}
  </div>
       

        <div style={{ overflow: 'hidden', marginTop: '10px', marginBottom: '10px', width: '100%' }}>
          <Grid style={{ display: 'flex', flexDirection: 'row', }}>
                      {visibleItems?.map((item: any, index: any) => {
                        const matchedCategory = getCategoryComplete?.find(
                          (category: any) => category === item?.label
                        );
                        return (
                          <Grid
                          data-test-id="gridValue"
                          onClick={() => this.handleCheckboxChange(item?.label)}
                            key={item.id}
                            style={{
                              cursor:'pointer',
                              width: '100%',
                              height: '204px',
                              position: 'relative',
                              marginRight: this.handleValue(index),
                              marginLeft: '10px',
                              border: matchedCategory
                                ? '3px solid transparent'
                                : '2px solid #E3E3E3',
                              borderRadius: '10px',
                              background: matchedCategory
                                ? 'linear-gradient(#EFF8FB, #EFF8FB) padding-box, linear-gradient(#EBFF00, #00A7D1) border-box'
                                : 'white',
                            }}
                          >
                            <Checkbox
                              checked={Boolean(matchedCategory)}
                              onChange={() => this.handleCheckboxChange(item?.label)}
                              sx={{
                                position: 'absolute',
                                borderRadius: '6px',
                                top: 0,
                                right: 0,
                                color: matchedCategory ? '#00A7D1' : '#E3E3E3',
                                '&.Mui-checked': {
                                  color: '#00A7D1',
                                },
                              }}
                            />
                            <div
                              style={{
                                position: 'absolute',
                                top: '35%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              <img
                                src={images[item.id-1]}
                                alt="Description"
                                style={{ width: '80px', height: '80px' }}
                              />
                            </div>
                            <div
                              style={{
                                position: 'absolute',
                                bottom: '72px',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                textAlign: 'center',
                                width: '300px',
                              }}
                            >
                              {item?.label}
                            </div>
                          </Grid>
                        );
                      })}
          </Grid>
        </div>
<div style={{height: '48px', width: '48px', minWidth:'48px',maxWidth:'48px'}}>
{scrollOffset + visibleItemCount < gridItems.length && (
          <img
            onClick={this.handleScrollRight}
            style={{ cursor: 'pointer', height: '48px', width: '48px', position: 'relative' , paddingRight:'10px'}}
            src={rightmovearrow.default}
            alt="Next"
          />
        )}
</div>

      </div>
                <Typography sx={{
                    fontFamily: 'Chromatica-Regular',
                    paddingTop: '10px',
                    fontSize: '16px',
                    color: '#252525',
                    zIndex:'2'
                  }}> Next, you can think through what you want to see happen for yourself. Click on <span style={{ fontFamily: 'Chromatica-Bold' }}>{this.state.valueCheck?"“Add Safer-use Strategies“":"“Set Goals”"}</span> below.
                  </Typography>
                  { this.state.getCategoryComplete?.length == 0 && this.state.AddCategorieButtonCalled && <p style={{ color: "#EB3E3E",
          fontFamily: "Chromatica-Regular",
          fontSize: "16px",
          fontWeight: 500,
          lineHeight: "24px",margin:0, marginTop:'5px'}}>Please select at least one substance, This field is required!</p>}
                  <Button
                  onClick={()=>{this.setGoals()}}
                    variant="contained"
                    sx={{
                      padding: "10px",
                      borderRadius: "16px",
                      boxShadow: 'none',
                      background:
                        "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                      textTransform: "none",
                      backgroundColor: '#EFF8FB',
                      fontSize: "18px",
                      lineHeight: "20px",
                      marginTop: '30px',
                      marginBottom: '30px',
                      cursor: 'pointer',
                      width:'clamp(180px, 50vw, 348px)',
                      height: '56px',
                      fontWeight: 400,
                      fontFamily: 'Chromatica-Bold',
                    }}
                    data-test-id="wellspring"
                  >
                    <span style={{ marginRight: '5px' }}>{this.state.buttonText}</span>
                    <img src={arrowright.default} />
                  </Button>
                <Box
                  sx={{
                    height: "auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    px: 4,
                  }}
                >
                  
                </Box>
                </Box>
                </Grid>          
          </Grid>
          </>
        )
    }
}

const webStyle = {
  backbutton:{
   
    left: 82,
    cursor: "pointer",
    color:'#252525',
    fontFamily: 'Chromatica-Bold',
  },
  gridItem:{
    height: 'auto',
    width:'100%',
    // margin: '10px',
    marginTop:'10px',
    marginBottom:'10px',
    borderRadius: '15px',
    backgroundColor: 'white',
   
  },
  name:{
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
    lineHeight: "20px",
    color: "#FFFFFF",
    marginTop: "3px",
    fontSize: "clamp(14px, 2vw, 20px)",
  },
  span:{
    fontSize: "40px",
    marginLeft: "40px",
    fontFamily: "Josefin Sans",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#EBFF00",
    lineHeight: "40px",
  },
  arrow:{
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },
  boxheader:{
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 20px",
    height: "100%",
  },
}

