import React from "react";
// Customizable Area Start
import VisualAnalytic from "../../visualanalytics/src/VisualAnalytic.web";
import { styled } from "@mui/material/styles"; 
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepConnector from "@mui/material/StepConnector";
import {  award_3, award_a4, award_a5, Menu_M,ThumbBoy_b, wave_wave,photo_photo } from "./assets";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box, Grid, IconButton, Button, Paper, Backdrop, CircularProgress } from "@mui/material";
import "../../../blocks/dashboard/assets/style.css"
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import "../../../blocks/dashboard/assets/style.css"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
  LabelList,
  ReferenceLine,
} from 'recharts';

import dayjs from 'dayjs'

const StepIconste = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
 
  "@media (max-width: 420px)": {
    width:60,
    height: 60,
  },
  zIndex: 1,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "rgb(204, 204, 204)",
  border: `2px solid ${completed ? '#FFEFAC' : '#fff'}`,
  boxShadow: "0 4px 10px 0 rgb(204, 204, 204)", 
  ...(completed && {
    backgroundColor: "#F3A622",
    boxShadow: "0 4px 10px 0 #F3A622", 
  }),
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  left: 'calc(-85% + 2px)',
  right: 'calc(15% + 2px)',
  display: 'block', // Display block as per your CSS
  borderColor: 'white',
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  borderRadius:"36px",

  alternativeLabel: {
    top: 22,
    marginRight:'10px',
    marginLeft:'10px',
    left: "-170px",
    right: "19px",
   
    
  },
  active: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  
  completed: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
}));
const ColorlibConnectorSafer = styled(StepConnector)(({ theme }) => ({
  
  display: 'block', // Display block as per your CSS
  borderColor: 'white',
  left: 'calc(-85% + 2px)',
  right: 'calc(15% + 2px)', // New border color
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  
  borderRadius:"36px",

  alternativeLabel: {
    left: "-170px",
    top: 22,
    right: "19px",
   
    
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
  completed: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  active: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  
  

}));


const ColorlibStepIcon = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
    zIndex: 1,
    "@media (max-width: 420px)": {
      width:60,
      height: 60,
    },
    color: "#fff",
    border: `2px solid ${completed ? '#FFEFAC' : '#fff'}`,
    width:80,
    height: 80,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgb(204, 204, 204)",
    boxShadow: "0 4px 10px 0 rgb(204, 204, 204)",
    ...(completed && {
      backgroundColor: "#F3A622",
      boxShadow: "0 4px 10px 0 #F3A622", 
    }),
  }));

const WhiteBackgroundContainer = styled('div')({
  background: 'white', 
  padding: '2rem',
  height:"auto",
  marginLeft:"0.7rem",
  marginRight:"0.7rem",
  borderRadius:"16px" ,

});

const StyledButton = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});


 
// Customizable Area End
import DashboardControllerInformation, {
  Props,
  configJSON
} from "./DashboardControllerInformation.web";
import moment from "moment";



export default class DashboardInformation extends DashboardControllerInformation {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


   icons = [
     <img src={award_3.default} alt="awrad_award"/>,
     <img src={award_a4.default} alt="awrad_award"/>,
     <img src={award_a5.default} alt="awrad_award"/>,
   ];
   headerMiddle = ()=>{
    return (
      <>
        <Grid
          item
          sx={{
           ...webStyles.wholeGridStyle,   
            
           "@media (min-width: 400px) and (max-width: 500px)": {
            width: '95.5% !important',
          },
          }}
        >
          <Box
                        sx={{                  
                          ...webStyles.wholedivStyle}}>
                        <Box
                            sx={{
                              ...webStyles.gapM
                            }}
                        >
                            <Box
                             data-test-id="parentwell"
                                sx={{
                                  ...webStyles.parentWell,
                                }} >
                                <span
                                    style={{
                                      ...webStyles.well__wellStyle,
                                    }}
                                >
                                    Well
                                </span>
                                Spring
                            </Box>
                            <Typography
                            data-test-id="fontsize"
                                sx={{
                                  ...webStyles.homeStylemenu
                                   
                                }}
                            >
                                Hey, {this.state.userName}</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenux() }}
                            component="img"
                            sx={{
                              cursor: "pointer",
                              marginRight: "50px",
                              "@media (max-width: 530px)": {
                                marginRight: "20px !important"},
                              "@media (min-width: 531px) and (max-width:1200px)": {
                                marginRight: "30px !important"}
                            }}
                            alt="menuIcon"      
                        />
                    </Box>
        </Grid>
      </>
    )
   }
 nextStepperFunc = () =>{
  return (
    <>
     <Grid item md={6} lg={6} sm={12} >
  <PaperHead style={{ paddingBottom:'10px', width: "100%", height: "100%",minHeight:'300px', background: "#DFEDF0", borderRadius: "16px" }}>
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box style={{ padding: "2rem" }} >
        <Typography sx={webStyles.goalStyles}>Safer-Use Strategies</Typography>
      </Box>
      <Stepper
        alternativeLabel
        activeStep={this.state.activeSteps}
        connector={<ColorlibConnectorSafer />}
        sx={this.state.saferResponse?.safer_user_strategies?.length > 0 ? webStyles.stepperStyle : webStyles.stepperZero}>
        {
        this.state.saferResponse?.safer_user_strategies?.length > 0 ? (
          this.state.saferResponse?.safer_user_strategies?.map((goal: any, index: any) => (
            <Step key={goal?.id}>
              <StepLabel
                StepIconComponent={(props) => (
                  <StepIconste {...props} completed={goal?.status === "achieved"}>
                    {this.icons[index]}
                  </StepIconste>
                )}
              >
                <span style={{...webStyles.labelstylesteppers,wordBreak:'break-word'}}>{goal?.title}</span>
              </StepLabel>
            </Step>
          ))
        ) : (
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '48px',
            height: '100%',
            fontFamily: 'Chromatica-Bold',
            fontSize: '64px !important',
            fontWeight: 700,
            padding: '20px',
            marginTop:'auto'
          }}>
            No task found
          </div>
        )
        }
      </Stepper>
      <Box sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexDirection: { sm: "row", xs: "column" },
        "@media (max-width: 420px)": {
          display: 'table !important',
          width:'100%',
        },
        marginTop: "auto"
      }}>
        <div style={{
          marginTop: "0.2rem",
          padding: "1.3rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <Box sx={{
            background: "#CFE472",
            width: { xs: "100%", sm: "250px", md: "195px" },
            borderRadius: "40px",
            padding: { xs: "8px 12px", sm: "10px 14px", md: "12px 16px" },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            margin: "0 auto",
          }}>
            <Typography sx={{...webStyles.threebythreeStyles,textTransform: 'capitalize',textAlign: "center", fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
            }}>{this.state.saferUserStrategyTotal.achieved}/{this.state.saferUserStrategyTotal.total} Strategies Completed!</Typography>
          </Box>
        </div>
        <div >
          <Typography sx={{...webStyles.viewStyle, "@media (max-width: 420px)": {  paddingRight:'0',textAlign:'center'}}} onClick={this.goToSaferseStrategies.bind(this) }>{configJSON.viewMore}</Typography>
        </div>
      </Box>
      
    </Box>
  </PaperHead>
</Grid>
    </>
  )
 }
 CustomTooltip = (props:any) => {
  const { active, payload, label } = props; 

  if (active && payload && payload.length) {
    const dataPoint = payload[0].payload; 
    return (
      <div style={{
        backgroundColor: '#fff',
        border: '1px solid #ccc',

        padding: '10px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
        ...webStyles.tooltipStyle, 
      }}>
        <p style={{ margin: 0 }}>
        {`${dataPoint?.date ? moment(dataPoint.date).format("MMMM Do") : 'N/A'}`}
  </p>
        <p style={{ margin: 0 }}>{`Score: ${dataPoint?.harm || 'N/A'}`}</p>
      </div>
    );
  }
  return null;
};

CustomLegend = ({ payload }:any) => {
  return (
    <>
  <div style={{textAlign:"center",margin:"1rem 0"}}> 
  {payload.map((entry:any, index:any) => (
        <li 
          key={`item-${index}`} 
          style={{ margin: '0 10px',   fontFamily: 'Chromatica-Regular',
            fontSize: '16px',
            fontWeight: 700,
            lineHeight: '20px',color:'#00A7D1'}} 
        >
        Months
        </li>
      ))}
  </div>
      </>
  
  );
};
  // Customizable Area End

  render() {
    // Customizable Area Start
   
    const updatedData = this.state.dataAns?.map((item: { date: string | number | Date; harm: any; }) => ({
      date: new Date(item.date).getTime(),
      harm: item.harm,
    }));
    return (
      //Merge Engine DefaultContainer
  <>
    <Backdrop style={{ color: '#fff', zIndex: 10 }} open={this.state.isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  <Dialog open={this.state.popupShow}
          PaperProps={{
            style: {
              borderRadius: 24, maxWidth: 692,
              overflowX: "hidden",boxShadow:"none"
            },
          }}
        >
          <DialogTitle>
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'left',
                  alignItems: 'left',
                  width: '100%',
                  padding: '2rem'

                }}
              >


                <Typography sx={webStyles.Congratulationsstyleinasfers} className="decreasefont">
                  Harm Reduction Questionnaire
                </Typography>

              </Box>
              <Box sx={{ paddingLeft: '2rem' }}>
                <Typography sx={webStyles.subtancesStyle} className="middletext">
                  WellSpring can help you reduce your substance-related harm. Please answer <br />the following questions so we can figure out your baseline and help you<br /> track your progress over time. It’s sometimes hard to look at substance-<br />related harm, but that’s why you’re here. You got this.
                </Typography>
              </Box>
            </Box>


          </DialogTitle>
          <Box
        
            sx={{
              width: '100%',
              height: '389px',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${photo_photo.default})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              borderRadius: '0 0 24px 24px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#fff',
            }}
            className="heightdown"
          >
            <Typography
              sx={{
                fontFamily: 'Chromatica-Regular',
                fontSize: '24px',
                fontWeight: 700,
                lineHeight: '24px',
                color: "#ffffff",
                textAlign: 'center',
                marginBottom: '16px',
              }}
              className="minutsstyele"
            >
              This should take you about 5 minutes
            </Typography>

            <Box sx={{ textAlign: 'center' }}>
              <StyledButton
              className="startqustmedia"
                onClick={() => this.goToassessment()}
                data-test-id="popupshow"
              >
                <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
                  Start Questionnaire
                </span>
              </StyledButton>
            </Box>
          </Box>

        </Dialog>
   <Grid
        item
        style={{
          background: '#f0f0f0',
        }}
      >
      {this.headerMiddle()}
        </Grid>
      
        <WhiteBackgroundContainer>
        <Box
                  component="img"
                  src={wave_wave.default}
                  alt="Logo"
                  sx={{
                    position: "absolute",
                    top:'111px',
                    left: 0,
                    width: '100%',
                    height: 'auto',
                    maxWidth: '600px',
                    display:{
                      md:'block',
                      xs:'none',
                  }
                  }}
                />
          <Box style={{ marginBottom: "2rem" }}>
            <Typography style={{...webStyles.YourStyles,position: "relative", zIndex: 2 }}>Your Dashboard</Typography>
          </Box>
          <Grid sx={{
           
              alignItems: 'stretch' 
          }} container spacing={4}>
            <Grid item md={6} lg={6} sm={12} style={{position: "relative", zIndex: 2, width: "100%" }}>
            
              <Paper style={{display:'flex',flexDirection:'column', paddingBottom:'10px', width: "100%", minHeight:'300px', height: '100%',  background: "#F1F7D0", borderRadius: "16px" }}>
                <Box style={{ padding: "2rem" }} >
                  <Typography sx={webStyles.goalStyles}>Goals</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeStep}
                  connector={<ColorlibConnector />}
                  sx={this.state.goalsResponse?.goals?.length>0?webStyles.stepperStyle:webStyles.stepperZero}
                >
                  {this.state.goalsResponse?.goals?.length>0? (
                  this.state.goalsResponse?.goals?.map((goal: any, index: any) => (
                    <Step key={goal?.id}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <ColorlibStepIcon {...props} completed={goal?.status === "achieved"}>
                            {this.icons[index]}
                          </ColorlibStepIcon>
                        )}
                      >
                        <span style={{...webStyles.labelstylesteppers,wordBreak:'break-word'}}>{goal?.title}</span>
                      </StepLabel>
                    </Step>
                  ))):<div style={{
                    display: 'flex',
                    fontSize: '64px !important',
                    fontWeight: 700,
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginBottom: '48px',
                    height: '100%',               
                    fontFamily: 'Chromatica-Bold',
                    padding: '20px',
                    marginTop:'auto'
                  }}>
                    No task found </div>
                }
                </Stepper> 
                    <Box
                      sx={{
                        display: "flex",
                        marginTop:'auto',
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap:"0",
                        flexDirection: { sm: "row", xs: "column" },
                        "@media (max-width: 420px)": {
                          display: 'table !important',
                          width:'100%'
                        }
                      }}
                    >
                      <div style={{
    padding: "1.3rem",
    marginTop: "0.2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}>
                        <Box sx={{
    width: { xs: "100%", sm: "250px", md: "195px" }, 
    background: "#CFE472",
    padding: { xs: "8px 12px", sm: "10px 14px", md: "12px 16px" },
    borderRadius: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "0 auto",
  }}>
                            <Typography sx={{...webStyles.threebythreeStyles,textTransform: 'capitalize', fontSize: { xs: "0.875rem", sm: "1rem", md: "1.125rem" },
      textAlign: "center",}}>{this.state.goalsTotalTask.achieved}/{this.state.goalsTotalTask.total} Goals Completed!</Typography>
                        </Box>
                      </div>
                      <Box>
                      <Typography data-test-id="checkid" sx={{...webStyles.viewStyle,"@media (max-width: 420px)": { textAlign:'center', paddingRight:'0'} 
}} onClick={this.goTogoalsScreen.bind(this) }>{configJSON.viewMore}</Typography>
                      </Box>
                    </Box>
              </Paper>
            </Grid>
          {this.nextStepperFunc()}
          </Grid>
          <Grid container style={{ marginTop: "2rem",position:'relative'}} spacing={2}>
            <Grid item md={12} lg={12}>           
              <Paper style={{ background: "#5C61DB", borderRadius: "16px", height: "auto" }}>
                <Box sx={webStyles.flexpropertys}>
                  <Box sx={{ ...webStyles.flexpropertys, display: "flex",
                  justifyContent: "space-between", alignItems: "flex-end", position: "relative" }}>
                    <Box sx={{ flex: 1,marginLeft: { xs: "0", sm: "1rem" }}}>
                      <Typography sx={{ ...webStyles.HeyTomStyles, margin: '18px' }}>
                        Hey, {this.state.userName}!
                      </Typography>
                      <Typography sx={webStyles.testStyleforHeyToms}>
                        No one's path in life is perfect. Great job coming back to keep working on things! <br />
                        This is a huge step towards changing your substance-related harm.
                      </Typography>
                      <span style={{ color: "#5C61DB" }}>none</span>
                    </Box>
                    <Box
                      sx={{
                        display: { xs: "none", sm: "block" },
                        width: "239px",
                        height: "160px"
                      }}
                    >
                      <img
                        src={ThumbBoy_b.default}
                        alt="Thumbnail"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                          maxWidth: "100%",
                        }}
                      />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>

          </Grid>
          <GridStyle  style={{ marginTop: "2rem" }}>
            <Grid item md={12} lg={12}>
              <Paper style={{borderRadius: "16px", height: "auto" }}>
             <Box  sx={{
    padding: { xs: '2px', sm: "1rem" },
    ...webStyles.leftAndrightbox,
  }}>
             <Box  sx={{  marginLeft: { xs: "0", sm: "1rem" }, marginTop:"0.6rem" , width: "100%",}}>
                <Typography sx={webStyles.relatedHarmstyle}>Substance-Related Harm Reduction</Typography>
              </Box>
                </Box>
                <div style={{ display: "flex", alignItems: "center" }}>
                <div className="vertical-text-container">
                <div className="vertical-text" style={{left:'0px'}}>
    <span style={{...webStyles.reductionStyle}}>Substance-Related Harm</span>  
  </div>
</div>
<VisualAnalytic navigation={undefined} id={""}/>
<ResponsiveContainer width="100%" height={400} style={{ marginTop: "1rem", marginLeft: '-39px' }}>
  <LineChart
    width={500}
    height={300}
    data={updatedData}
    margin={{ top: 10, right: 30, left: 20, bottom: 5 }}
  >
    <CartesianGrid strokeDasharray="3 3" />
    <XAxis 
    style={webStyles.XandYbothStyle} 
    dataKey='date'  
    interval={this.state.interval}    
    ticks={this.state.nextSixMonthsAns2} 
    type="number"
     domain={["dateMin","dateMax"]}
      tickFormatter={(tick)=>this.formatTickToShortMonth(tick)}/>
    <LabelList dataKey="harm" position="top"/>
    <YAxis ticks={[0, 5, 10, 15, 20, 25, 30, 35, 40, 45]} domain={[0, 45]} style={webStyles.XandYbothStyle} />
    <Tooltip content={<this.CustomTooltip />} />
    <Legend content={<this.CustomLegend />} />
    <Line type="monotone" dataKey="harm" stroke="#00A7D1"  dot={{ r: 4, fill: '#00A7D1' }}  activeDot={{ r: 7, fill: '#00A7D1' }} />
    {this.state.midOfMonthData?.map((data:any, index:any) => (
        <ReferenceLine key={index} x={data?.date} stroke="#00A7D1" strokeDasharray="3 3" />
      ))}
  </LineChart>
</ResponsiveContainer>
</div>

              </Paper>
            </Grid>

          </GridStyle>
        </WhiteBackgroundContainer>
        <Dialog onClose={this.handleClosestartqustionary} open={this.state.qustionStart}
          PaperProps={{
            style: { borderRadius: 24 },
            background: "#0F4257"
          }}
        >
          <DialogTitle sx={{ background: "#0F4257" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                width: '100%',
              }}
            >

              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                <Typography
                  sx={{
                    ...webStyles.SprinStylethird,
                    fontSize: { xs: '25px', sm: '30px', md: '40px' },
                    "@media (max-width: 500px)": {
                      fontSize: '25px !important',
                    },
                    "@media (min-width:501px) and (max-width: 580px)": {
                      fontSize: '30px !important',
                    },
                  }}
                >
                  Well
                </Typography>

                <Typography
                  sx={{
                    ...webStyles.wellStylethird,
                    fontSize: {
                      xs: '25px',
                      sm: '30px',
                      md: '40px',
                    }
                  }}
                >
                  Spring
                </Typography>
              </div>
              <Typography sx={{ ...webStyles.QuestionnaireStyle, marginTop: "1.1rem" }}>
                Go to Harm Reduction Questionnaire to track your <br />
                progress every two weeks.
              </Typography>

            </Box>
            <Box
              style={{
                ...webStyles.flexBoxthird
              }}
            >
              <Box>
                <button
                  style={{ ...webStyles.harmChartTextStyle, cursor: "pointer" }}
                >
                  <Typography sx={{ ...webStyles.achievegoalstext, color: "white" }}>
                    Start Questionnaire
                  </Typography>
                </button>
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={this.handleClosestartqustionary}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Dialog>

        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    
    // Customizable Area End
  }
}

// Customizable Area Start
const PaperHead = styled(Paper)({
  "@media (max-width: 599px)": {
    width: '750px'
  },
});
const MonthBox= styled(Box)({
  "@media (max-width: 420px)": {
    marginLeft:'-30px !important'
  },
  "@media (min-width:421px and max-width: 422px)": {
     marginRight:'40px important',
    marginLeft:'0px !important'
  },
  "@media (min-width:423px and max-width: 430px)": {
    marginRight:'50px important',
    marginLeft:'0px !important'
  },
});
const GridStyle = styled(Box)({
  "@media (min-width:349px and max-width: 419px)": {
    width:'250px'
  },
  "@media (min-width: 420px) and (max-width: 466px)": {
    width:'350px'
  },
  "@media (min-width: 467px) and (max-width: 538px)": {
    width:'400px'
  },
  "@media (min-width: 539px) and (max-width: 573px)": {
    width:'450px'
  },
  "@media (min-width: 574px) and (max-width: 638px)": {
   width:'500px'
  },
  "@media (min-width: 639px) and (max-width: 660px)": {
    width:'545px'
  },
  "@media (min-width: 661px) and (max-width: 700px)": {
    width:'570px'
  }
})
const webStyles = {
  wholedivStyle:{
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    height: "100%"
  },
  wholeGridStyle:{
    height: '88px',
    backgroundColor: '#0F4257',
    borderRadius: '16px',
     margin: '10px',

  },
  well__wellStyle:{
    marginLeft: "17px",
    fontFamily: "Josefin Sans",
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#EBFF00",
    fontSize: "clamp(25px, 5vw, 40px)",
   },
   homeStylemenu:{
    mt: "3px",
    fontSize: "clamp(14px, 2vw, 20px)",
    lineHeight: "20px",
    color: "#FFFFFF",
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
  },
  gapM:{
    gap: "20px",
    display: "flex",
    alignItems: "center",
  },
  parentWell:{
    fontFamily: "Josefin Sans",
    color: "#ffffff",
    fontSize: "clamp(25px, 5vw, 40px)",
    fontWeight: 300,
  },

  //above my style for header

  achievegoalstext:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"white",
    textAlign:'center'
    
 },

  harmChartTextStyle:{
    padding: "0.8rem",     
    width: "348px",          
    borderRadius: "8px",     
    border: "none",       
    color:"white",    
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
},
  QuestionnaireStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color:"white"
  },
  reductionStyle:{
    color:'#00A7D1',
    fontFamily: 'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  viewStyle:{
   fontFamily:'Chromatica-Bold' ,
   textDecoration:'underline',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    paddingRight: "2rem",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline"
    },
    "&:active": {
      textDecoration: 'underline',
    },
  },
  stepperStyle:{
    marginRight:'10px',
    marginLeft:'10px',
  },
  stepperZero:{
    fontSize:'24px',
    lineHeight: '32px',
    alignItems:'center',
    display:'flex',
    justifyContent:'center'
  },
  tooltipStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#00A7D1",
    borderRadius:"33px"
  },
 
  XandYbothStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"
  },
  leftAndrightbox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
   
  },

  relatedHarmstyle:{
   fontFamily:'Chromatica-Bold' ,
    fontWeight: 700,
    color:"#000000",
    fontSize: { xs: "18px", sm: "20px", md: "24px" },
    lineHeight: { xs: "26px", sm: "28px", md: "32px" }, 
    padding: { xs: "0.5rem", sm: "0.75rem", md: "1rem" },
  },
  flexpropertys:{
   
display:"flex",
justifyContent:"space-between",
alignItems:"center"
  },
  testStyleforHeyToms:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color:"#ffffff",
    margin:"18px"
  },
  HeyTomStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"#EBFF00"
  },
  threebythreeStyles:{

   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
  },
  labelstylesteppers:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyles:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStyles:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1",

},

SprinStylethird:{
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"#EBFF00",
  marginTop:"1.2rem",
},
flexBoxthird:{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.8rem",
  marginTop:"1rem",
  marginBottom:"2rem"
},
wellStylethird:{ 
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"white",
  marginTop:"1.2rem",
},
subtancesStyle:{
  fontFamily: 'Chromatica-Regular',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color:"#252525"
},
closeStyle:{
  position: 'absolute',
  right: 8,
  top: 8,
},
Congratulationsstyleinasfers:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:'#00A7D1',
},
Questionnairestyleinasfers:{
fontFamily: 'Josefin Sans',
fontSize: '40px',
fontWeight: 700,
lineHeight: '40px',
letterSpacing: '-0.04em',
color:'#EBFF00',
},
QustionStyleText:{
fontFamily: 'Chromatica-Regular',
fontSize: '20px',
fontWeight: 500,
lineHeight: '24px',
color:'white',display:"flex",justifyContent:'center',alignItems:"center"
},
Nextstylemove:{
  fontFamily: 'Chromatica-bold', 
  fontSize: '18px', 
  fontWeight: 700, 
  lineHeight: '24px',
  cursor:"pointer"
 }
};



// Customizable Area End